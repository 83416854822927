import request from '@/router/axios';

export const getList = (token,data) => {
  return request({
    token,
    url: `/api/front/iot-customer/firmware/upgrade/listPage`,
    method: 'POST',
    data
  })
}

export const appConfirm = (token,data) => {
  return request({
    token,
    url: '/api/front/iot-customer/firmware/upgrade/appConfirm',
    method: 'POST',
    data
  })
}

