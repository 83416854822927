<template>
  <div class="serviceInfo">
     <div class="none-box" v-if='content.length == 0'>
       <img src="img/none.png" class="none-img" >
       <div class="text">暂无数据</div>
    </div>
    <p v-html="content">如何使用天猫精灵控制全宅卡威设备</p>
  </div>
</template>
<script>
import { getDetail } from "@/api/helpInfo/help_info";
export default {
  name: "serviceInfo",
  data() {
    return {
      token:"",
      id:'',
      content:' '
    };
  },
  props: {
    msg: String,
  },
  created: function() {
    console.log(" this.$router ",this.$route);
    this.id = this.$route.query.id;
    console.log(" this.id ",this.id);
    this.getToken();
    // this.onLoad();
  },
  mounted: function() {},
  methods: {
    getToken(){
      this.$bridge.callHandler('getAccessToken',null,(res)=>{
      //   // alert('获取app响应数据:'+res)
        this.token = "Bearer " + res;
        this.onLoad(this.page);
      })
    },
    receiveMsgFromParent: function(msg) {
      //webView 传递参数
      alert("" + msg);
      this.childMsg = "receive msg = " + msg;
    },
    onLoad() {

      this.loading = true;
      getDetail(this.token,this.id).then((res) => {
        const data = res.data.data;
        console.log(" data ",data);
        this.content = decodeURI(data.content)
        this.loading = false;
      });
    },
  },
};
</script>
<style scoped>
.none-box{
  position: absolute;
  width: calc(100% - 20px);
  margin-top: 240px;
  z-index: 999;
}
.none-img{
 width: 40%;
}
</style>