<template>
  <div class="timeaxis">
    <div class="xhdl">
      <div :class="{ xh: true, online_color:deviceInfo.isOnline,offline_color:!deviceInfo.isOnline}">
        {{deviceInfo.isOnline?"在线":"离线"}}
      </div>
      <div class="dl1">
        <div class="shijian">

        </div>
      </div>
      <div class="dl">
        <div class="dl2" :style="{width: dlWidth +'px',background: electricColor }">

        </div>
      </div>
    </div>
    <div class="chartStyle">
      <div>
        <div class="dateTimeStyle">
          <div @click.stop.prevent='selectDate' class="dateTimeStyle1">{{showDateTime}}</div>
          <div @click.stop.prevent='selectDate' class="dateTimeStyle2"></div>
        </div>
        <div class="kongbai"></div>
        <div class="dateTimeStyle">
          <el-select
              v-model="clickMethod"
              default-first-option
              :popper-append-to-body="false"
              popper-class="popper-scrollbar-show"
              @change="change"
          >
            <el-option v-for="item in actionData" :key="item.value" :value="item.value" :label="item.label" />
          </el-select>
        </div>
      </div>
      <section>
        <div id="showFreepChart" :style="{height:height,width:width}">
        </div>
      </section>
    </div>
    <div class="timeAxisStyle">
      <div v-for="(item,index) in items" :key="item.id" :class="{boxshadow:index==0}">
        <div class="timeLine">
          <!--<span :class="{circleOne:index != 0,circle:index == 0}"></span>-->
          <span :class="{circle:true,circleblue:index%3==0,circleprint:index%3==1,circlegreen:index%3==2}" ></span>
          <div :class="{rightsborderleft:index != itemsLength,rights:true}" >
            <span class="content-time">{{item.hourMinuteSecond}}</span>
            <span class="content-status">{{item.stateName}}{{item.customizeValue}}</span>
            <span class="content-date">{{item.yearMonthDay}}</span>
          </div>
        </div>
        <div class="bottomclass" v-if="index != itemsLength-1">

        </div>
      </div>
      <div class="downloading" @click.stop.prevent="appLogList">
        <div :class="{down:true,downjian:isloading==0,trun:isloading==1,nodata:isloading==2}">
          {{ this.showTips }}
        </div>
      </div>
    </div>
    <calendar @change="onChange" class="calendar-box" :show.sync="showDate" />
  </div>
</template>


<script>
import moment from 'moment';
import * as echarts from 'echarts'
require('echarts/theme/macarons') // echarts theme
import {appLogList, getDeviceDetail
  , getBarChartData
} from '@/api/deviceLog/device_log'
import VuePicker from 'vue-pickers'

export default {
  name: "timeAxisFreep",
  props: {
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    }
  },
  data() {
    return {
      token: "",
      deviceId: '',
      chartData: null,
      channelNameList: null,
      hourList: null,
      isloading: 0,
      dlWidth: 27,
      electricColor: '#49C168',
      showTips: "",
      showDate:false,
      showDateTime:'',
      clickMethod: 'click',
      actionData:[{
              label: '单击',
              value: 'click'
            },
            {
              label: '双击',
              value: 'doubleClick'
            },
            {
              label: '长按',
              value: 'press'
            }],
      myChart:null,
      items: [],
      deviceInfo:{},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      isFirstPage:true,
      isHasNextPage:true,
      itemsLength: 10,
      startTime: null,
      endTime: null
      };
    },
    components: {
      VuePicker
    },
    created: function() {
    },
    mounted() {
      const chartDom = document.getElementById('showFreepChart');
      this.myChart = echarts.init(chartDom);
      this.getToken();
    },
    methods: {
      getToken(){
        this.$bridge.callHandler('getAccessToken',null,(res)=>{
          console.log(" getAccessToken "+res);
          this.token = "Bearer " + res;
          this.$bridge.callHandler('getDeviceId',null,(deviceId)=>{
            console.log(" deviceId "+deviceId);
            this.deviceId = deviceId;
            this.startTime = moment().startOf('day');
            this.endTime = moment(this.startTime).subtract(-1, 'days');
            this.showDateTime = moment().format("YYYY-MM-DD");
            this.getDeviceDetail();
            this.getFreepLog();
            this.appLogList();
        })
      })
      },
      selectDate() {
        this.showDate = true;
        this.date = "";
      },
      onChange(date) {
        this.showDate = false;
        if( date > moment() ) {
          this.$message({
            type: 'error',
            message: '选择日期不能大于当前日期!'
          })
          return ;
        }
        this.startTime = date;
        this.endTime = date.subtract(-1, 'days');
        this.showDateTime = date.format("YYYY-MM-DD");
        this.getFreepLog();
        this.isFirstPage = true;
        this.isHasNextPage = true;
        this.appLogList();
      },
      initChart() {
        this.myChart.clear();
        let option = this.getChartOption();
        this.myChart.setOption(option);
      },
      getChartOption(){
        if(this.chartData == null || this.chartData.length == 0){
          return {graphic: {
            elements: [{
              type: 'text',
              left: 'center',
              top: 'middle',
              style: {
                text: '暂无数据',
                textAlign: 'center',
                fontSize: 16,
                fill: '#999'
              }
            }]
          }};
        }
        const labelOption = {
          show: true,
          position: 'insideTop',
          distance: -5,
          align: 'center',
          color: 'black',
          verticalAlign: 'middle',
          rotate: 0,
          formatter: '{c}',
          fontSize: 10
        };
        let seriesData = [];
        for (let i = 0; i < this.channelNameList.length; i++) {
          for (let j = 0; j < this.chartData[i].length; j++) {
            if (this.chartData[i][j] === 0) {
              this.chartData[i][j] = null;
            }
          }
          seriesData.push({
            name: this.channelNameList[i],
            type: 'bar',
            barGap: 0,
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: this.chartData[i]
          });
        }
        return {
          legend: {
            data: this.channelNameList
          },
          xAxis: [
            {
              type: 'category',
              axisTick: { show: false },
              data: this.hourList,
              axisLabel:{
                rotate: -40,
                formatter:'{value}时'
              },
            }
          ],
          dataZoom: {
            type: 'inside'
          },
          yAxis: [
            {
              type: 'value',
              minInterval: 1,
              name: '(次)',
              nameLocation:'center',
              nameGap: 20
            }
          ],
          series: seriesData
        };
      },
      getDeviceDetail(){
        getDeviceDetail(
            this.token,
            this.deviceId
        ).then((res) => {
          this.deviceInfo = res.data.data;
          if(this.deviceInfo.features[0] != null && this.deviceInfo.features[0]['electric'] != null) {
            this.dlWidth = this.dlWidth * this.deviceInfo.features[0]['electric']['value']
            this.dlWidth = this.dlWidth / 100;
            this.dlWidth = this.dlWidth > 27 ?27:this.dlWidth<0?0:this.dlWidth;
            this.electricColor = this.dlWidth<2.7?"#ef1e08": this.electricColor;
          }
        });
      },
      getFreepLog(){
        let params = {
          "startTime":this.startTime,
          "endTime":this.endTime,
          "deviceId":this.deviceId,
          "commandSource": 1,
          "attributeCode":"reportStatus",
          "attributeItemCode":this.clickMethod
        };
        getBarChartData(
            this.token,
            params
        ).then((res) => {
          this.channelNameList = res.data.data.channelNameList;
          this.hourList = res.data.data.hourList;
          this.chartData = res.data.data.chartData;
          this.initChart();
        });
      },
      appLogList(){
        if( !this.isHasNextPage ){
          return;
        }
        if( this.isFirstPage ) {
          this.page.pageSize = 10;
          this.page.currentPage = 1;
          this.showTips = "";
          this.items = [];
          this.itemsLength = this.items.length;
        }
        this.isloading = 1;
        let params = {
          "deviceId":this.deviceId,
          "commandDirection":0,
          "commandSource": 1,
          "year":this.startTime.year(),
          "month":this.startTime.month()+1,
          "day":this.startTime.date(),
          "pageSize":this.page.pageSize,
          "index":this.page.currentPage
        };
        appLogList(
            this.token,
            params
        ).then((res) => {
          this.isFirstPage = false;
          // this.resDate = res.data.data;
          // const data = res.data.data;
          let lastlistnum = res.data.data.records.length;
          if (lastlistnum > 0) {
            this.page.currentPage++;
            this.items = this.items.concat(res.data.data.records);
            this.itemsLength = this.items.length;
            this.isloading = 0;
          } else {
            this.isHasNextPage = false;
            this.isloading = 2;
            this.showTips = "无";
          }
        });
      },
      change () {
        this.getFreepLog();
      }
    },
  };
</script>


<style>
  .timeaxis {
    padding: 20px;
  }
  .circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    margin: 5px 0;
  }
  .circleblue{
    border: 2px solid #6776F5;
  }
  .circleprint{
    border: 2px solid #F46587;
  }
  .circlegreen{
    border: 2px solid #3DF5F3;
  }
  .timeLine{
    height: 22px;
    line-height: 22px;
  }
  .rights{
    display: inline-block;
    width: 90%;
    height: 22px;
    line-height: 22px;
    font-size: 18px;

  }
  .content-time{
    float: left;
    color: #333333;
    margin-left: 15px;
  }
  .content-status{
    color: #333333;
    float: left;
    margin-left: 15px;
  }
  .content-date{
    color: #999999;
    float: right;
  }
  .bottomclass{
    width: 0px;
    height: 33px;
    border: 1px solid #E5E5E5;
    margin-left: 16px;
    margin-top: -5px;
  }
  .xhdl {
    height: 85px;
    border-bottom: 1px solid #F4F4F4;
  }
  .xh{
    /*margin-top: 73px;*/
    display: inline-block;
    float: left;
    height: 33px;
    width: 60px;
    text-align: right;
    line-height: 33px;
    padding-right: 10px;
    color: white;
    font-weight: 600;
    margin-top: 22px;
    border-radius: 17px;
  }
  .offline_color{
    background: #f90404;
  }
  .online_color{
    background: #49C168;
  }
  .dl1{
    display: inline-block;
    float: right;
    background: #999999;
    border: 1px solid #999999;
    margin-top: 36px;

    height: 2.6px;
    border-radius: 1px;
  }
  /**
  width最大值27px
  */
  .dl2{
    border: 1px solid #FFFFFF;
    height: 11px;
    border-radius: 2.6px;
  }
  .dl{
    display: inline-block;
    float: right;
    height: 13px;
    width: 29px;
    border: 1px solid #999999;
    margin-top: 31px;
    border-radius: 2.6px;
  }
  .chartStyle{
    height: 400px;
  }
  .boxshadow{
    box-shadow: 0px -8px 0px 0px rgba(163, 163, 163, 0.1);
    border-radius: 5px;
  }
  .kongbai{
    width: 16px;
    display: inline-block;
  }
  .dateTimeStyle{
    width: 140px;
    height: 56px;
    display: inline-block;
    margin-top: 22.4px;
  }
  .dateTimeStyle1{
    width: 140px;
    height: 48px;
    border-radius: 24px;
    font-size: 22.4px;
    line-height: 48px;
    color: #262626;
    background: #F4F4F4;
  }
  .dateTimeStyle2{
    width: 0px;
    height: 0px;
    margin: 0 auto;
    border-left: 8px solid white;
    border-right: 8px solid white;
    border-top: 8px solid #F4F4F4;
  }
  /*时间轴样式end*/
  .timeAxisStyle{
    margin: 0 0 0 0;
    padding: 0;
  }
  .downloading{
    margin: 10px 0 0 0;
    padding: 0;
    width: 100%;
  }

  .down {
    display: inline-block;
    height: 20px;
    width: 20px;
  }

  .downjian {
    border-right: 1px solid;
    border-bottom: 1px solid;
    transform: rotate(45deg);
  }

  .trun {
    animation: turn 1s linear infinite;
    border-radius: 50%;
    border-bottom: 1px solid #4e4141;
    border-top: 1px solid #4e4141;
  }

  .nodata {
    color: #999999;
  }

  @keyframes turn {
    0% {
      -webkit-transform: rotate(0deg);
    }
    25% {
      -webkit-transform: rotate(90deg);
    }
    50% {
      -webkit-transform: rotate(180deg);
    }
    75% {
      -webkit-transform: rotate(270deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  /*
  解决el-select组件在iOS移动端的兼容问题，点击两次才能选中
  */
  .popper-scrollbar-show{
    .el-scrollbar{
      > .el-scrollbar__bar.in-vertical {
        display: block !important;
      }
    }
  }
</style>
