<template>
  <div class="hello">
    <div class="none-box"  v-if="isEmpty">
       <img src="img/none.png" class="none-img" >
       <div class="text">暂无数据</div> 
    </div>
    <header class="header">
      <nav class="nav">
        <a v-on:click.stop="doDay" :class="{ cur: cur == 'day' }">日</a>
        <a v-on:click.stop="doWeek" :class="{ cur: cur == 'week' }">周</a>
      </nav>
    </header>
    <!-- {{currentSignalValue}} -->
    <!-- {{token.length}}
    {{id.length}} -->
    <section class="content" v-if="cur == 'week'">
      <div class="item">
        <ul>
          <li>
            <!-- <div class="count"><span>-</span>度</div>
            <p class="info">上周用电量</p> -->
            <img src="img/four.png" class="signo-img" v-if="currentSignalValue.signalLevel == '优'">
            <img src="img/three.png" class="signo-img" v-if="currentSignalValue.signalLevel == '良'">
            <img src="img/two.png" class="signo-img" v-if="currentSignalValue.signalLevel == '差'">
          </li>
          <li>
            <div class="count"><span>{{currentSignalValue.signalValue}}</span>信号</div>
            <p class="info">{{currentSignalValue.signalLevel}}</p>
          </li>
        </ul>
      </div>
      <div class="charts_box">
        <!-- <div class="title"><i class="dot"></i>最新用电量</div> -->
        <div
          class="charts"
          id="weekEcharts"
          :style="{ height: curHeight }"
        ></div>
        <div class="tools">
          <div class="left_arrow" @click="weekBefor()">
            <img src="img/left.png" />
          </div>
          <div class="right_arrow" @click="weekNext()">
            <img src="img/right.png" />
          </div>
        </div>
      </div>
    </section>
    <section class="content" v-if="cur == 'day'">
      <div class="item">
        <ul>
          <li>
            <!-- <div class="count"><span>-</span>度</div>
            <p class="info">上年用电量</p> -->
            <img src="img/four.png" class="signo-img" v-if="currentSignalValue.signalLevel == '优'">
            <img src="img/three.png" class="signo-img" v-if="currentSignalValue.signalLevel == '良'">
            <img src="img/two.png" class="signo-img" v-if="currentSignalValue.signalLevel == '差'">
          </li>
          <li>
            <div class="count"><span>{{currentSignalValue.signalValue}}</span>信号</div>
            <p class="info">{{currentSignalValue.signalLevel}}</p>
          </li>
        </ul>
      </div>
      <div class="charts_box">
        <!-- <div class="title"><i class="dot"></i>最新用电量</div> -->
        <div
          class="charts"
          id="dayEcharts"
          :style="{ height: curHeight }"
        ></div>
        <div class="tools">
          <div class="left_arrow"  @click="dayBefor()">
            <img src="img/left.png" />
          </div>
          <div class="right_arrow" @click="dayNext()">
            <img src="img/right.png" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import moment from 'moment'
import { getList as homeDeviceZigbeeMinuteLog } from "@/api/homeDeviceZigbeeMinuteLog/home_device_zigbee_minute_log";
import { getList as homeDeviceZigbeeWeekLog } from "@//api/homeDeviceZigbeeWeekLog/home_device_zigbee_week_log";
// 引入 ECharts 主模块
let echarts = require("echarts/lib/echarts");
// 引入折线图/柱状图等组件
require("echarts/lib/chart/line");
require("echarts/lib/chart/bar");
require("echarts/lib/chart/radar");
require("echarts/lib/chart/gauge");
// 引入提示框和title组件，图例
require("echarts/lib/component/toolbox");
require("echarts/lib/component/grid");
require("echarts/lib/component/tooltip");
require("echarts/lib/component/title");
require("echarts/lib/component/legend");
require("echarts/lib/component/legendScroll"); //图例滚动
require("echarts/lib/component/dataZoom");

export default {
  name: "zigbee",

  data() {
    return {
      isEmpty:true,
      cur: "day",
      id:'',
      token:"",
      curHeight: 100,
      weekChart: null,
      dayChart: null,
      weekPage: {
        pageSize: 7,
        currentPage: 1,
        total: 0,
      },

      dayPage: {
        pageSize: 24,
        currentPage: 1,
        total: 0,
      },
      hours:["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12","13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "00"],
      
      weekDayArray:[],
      weekOfday:'',
      start:'',
      end:'',
      dayOfHour:'',
      currentSignalValue:""
    };
  },
  props: {
    msg: String,
  },
  created: function() {
      this.dayOfHour = moment().format('YYYY-MM-DD');
      this.getToken();

      setTimeout(() => {}, 200);
  },
  mounted: function() {
 
  },
  // //数据自动刷新，必然需要一个监听机制告诉Echarts重新设置数据
  // watch: {
  //   //观察option的变化
  //   option: {
  //     handler(newVal, oldVal) {
  //       if (this.myChart) {
  //         if (newVal) {
  //           this.myChart.setOption(newVal);
  //         } else {
  //           this.myChart.setOption(oldVal);
  //         }
  //       } else {
  //         this.charWeek();
  //       }
  //     },
  //     deep: true, //对象内部属性的监听，关键。
  //   },
  // },

  methods: {
    getWeek(weekOfdayValue){
      this.start = moment().subtract(weekOfdayValue, 'days').format('YYYY-MM-DD 00:00:00') // 周一日期
      this.end = moment().add(7 - weekOfdayValue - 1, 'days').format('YYYY-MM-DD 23:59:59') // 周日日期

      this.weekDayArray = [];
      this.weekDayArray.push(moment(this.start).format("MM/DD周日"))
      this.weekDayArray.push(moment(this.start).subtract(-1, 'days').format("MM/DD"))
      this.weekDayArray.push(moment(this.start).subtract(-2, 'days').format("MM/DD"))
      this.weekDayArray.push(moment(this.start).subtract(-3, 'days').format("MM/DD"))
      this.weekDayArray.push(moment(this.start).subtract(-4, 'days').format("MM/DD"))
      this.weekDayArray.push(moment(this.start).subtract(-5, 'days').format("MM/DD"))
      this.weekDayArray.push(moment(this.end).format("MM/DD周六"))
      console.log(" weekOfday " +this.weekOfday);
      console.log(" start " +this.start);
      console.log(" end " +this.end);
      console.log(" this.weekDayArray " +this.weekDayArray);
    },
    getToken(){
      this.weekOfday = parseInt(moment().format('d')) ;//计算今天是这周第几天
      this.getWeek(this.weekOfday);
      // this.charDay();
 
      this.$bridge.callHandler('getAccessToken',null,(res)=>{
      //   // alert('获取app响应数据:'+res)
        this.token = "Bearer " + res;
        this.$bridge.callHandler('getId',null,(id)=>{
           console.log(" id "+id); 
          //  alert(" id "+id);
           this.id = id;
           this.lastDate();
           this.charDay();
        })
      })
    },
    weekBefor(){
       this.weekPage.currentPage--;
       this.getWeek(this.weekOfday+=7);
       this.doWeek();
       this.lastDate();
    },
    weekNext(){
      this.weekPage.currentPage++;
      this.getWeek(this.weekOfday-=7);
      this.doWeek();
      this.lastDate();
    },
    dayBefor(){
       this.dayPage.currentPage--;
       this.dayOfHour = moment(this.dayOfHour).subtract(1, 'days').format("YYYY-MM-DD")
       this.doDay();
       this.lastDate();
    },
    dayNext(){
      this.dayPage.currentPage++;
      this.dayOfHour = moment(this.dayOfHour).subtract(-1, 'days').format("YYYY-MM-DD")
      this.doDay();
      this.lastDate();
    },
    doWeek() {
     
      this.cur = "week";
      // 基于准备好的dom，初始化echarts实例
      this.$nextTick(() => {
        //  alert("0");
        this.weekOfday = parseInt(moment().format('d')) ;//计算今天是这周第几天
        this.getWeek(this.weekOfday);
        this.charWeek();
        this.lastDate();
      });
    },
    doDay() {
      this.cur = "day";
      this.$nextTick(() => {
        this.charDay();
      });
    },
    lastDate(){
       //请求接口数据
      homeDeviceZigbeeMinuteLog(
        this.token,
        this.dayPage.currentPage,
        this.dayPage.pageSize,{
          deviceId:this.id,
          createTime: moment().format('YYYY-MM-DD')
        }
      ).then((res) => {
        const data = res.data.data.records;
        console.log(" this.data ", data);
      
        
        this.currentSignalValue = data[data.length -1] ;
        // alert(this.currentSignalValue);
        console.log(" this.currentSignalValue == ", this.currentSignalValue);
      });
    },
    charWeek() {
         // 指定图表的配置项和数据
      this.weekOption = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          icon: "circle",
          top: "5%",
          left: "5%",
          itemWidth: 8,
          itemGap: 20,
          textStyle: {
            color: "#556677",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            // saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: true, //坐标轴两边留白
          data:  this.weekDayArray,
          axisLabel: {
            //坐标轴刻度标签的相关设置。
            show: true,
            interval: 0, //设置为 1，表示『隔一个标签显示一个标签』
            // margin:15,
            // width:100,
            textStyle: {
              color: "#666",
              fontStyle: "normal",
              fontFamily: "微软雅黑",
              fontSize: 12,
            },
            formatter: function(params) {
              var newParamsName = "";
              var paramsNameNumber = params.length;
              var provideNumber = 5; //一行显示几个字
              var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
              // console.log(params);
              if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  var start = p * provideNumber;
                  var end = start + provideNumber;
                  if (p == rowNumber - 1) {
                    tempStr = params.substring(start, paramsNameNumber);
                  } else {
                    tempStr = params.substring(start, end) + "\n";
                  }
                  newParamsName += tempStr;
                }
              } else {
                newParamsName = params;
              }
              return newParamsName;
            },
            //rotate:50,
          },
          axisTick: {
            //坐标轴刻度相关设置。
            show: false,
          },
          axisLine: {
            //坐标轴轴线相关设置
            show: false,
            lineStyle: {
              color: "#E5E9ED",
              // opacity:0.2
            },
          },
          splitLine: {
            //坐标轴在 grid 区域中的分隔线。
            show: false,
            lineStyle: {
              color: "#E5E9ED",
              opacity: 0.1,
            },
          },
        },
        yAxis: [
          {
            type: "value",
            splitNumber: 5,
            axisLabel: {
              show: false,
              textStyle: {
                color: "#a8aab0",
                fontStyle: "normal",
                fontFamily: "微软雅黑",
                fontSize: 12,
              },
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#E5E9ED",
                type: "dashed",
                // 	opacity:0.1
              },
            },
          },
        ],
        series: [
          {
            name: "信号值",
            type: "line",
            symbol: "none",
            itemStyle: {
              normal: {
                color: "#12A9E6",
                lineStyle: {
                  color: "#12A9E6",
                  width: 1,
                },
                areaStyle: {
                  color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      offset: 0,
                      color: "rgba(255,255,255,0)",
                    },
                    {
                      offset: 1,
                      color: "rgba(118,231,248,1)",
                    },
                  ]),
                },
              },
            },
            data: [],
          },
        ],
      };
      this.weekChart = echarts.init(document.getElementById("weekEcharts"));
      // this.weekChart = this.$echarts.init(this.$refs.weekEcharts);
      
      this.autoHeight = document.documentElement.clientHeight || document.body.clientHeight;

      this.curHeight = this.autoHeight - 100; //减去页面上固定高度height

      this.weekChart.resize({ height: this.curHeight });
      //请求接口数据
      homeDeviceZigbeeWeekLog( this.token, this.weekPage.currentPage, this.weekPage.pageSize,{
        deviceId:this.id,
        startDate:this.start,
        endDate:this.end
      } ).then((res) => {
          const data = res.data.data.records;
          console.log(" this.data ", data);
          let labelArr = [];
          let signalValueArr = [];
          this.isEmpty =true;
          this.weekDayArray.forEach((itemDate) =>{
          let tmpDate = itemDate.split('周日').join('').split('周一').join('');
          let signalValue = 0;
          for(let i=0;i< data.length;i++){
           let tmpLab  = data[i].createTime.substring(5,10).split('-').join('/');
             console.log(" tmpDate ",tmpDate );
             console.log(" tmpLab ",tmpLab );
            if(tmpDate == tmpLab){
              this.isEmpty = false;
              signalValue = data[i].signalValue;
            }
          }
          signalValueArr.push(signalValue);
        })

       
        console.log("labelArr == ", labelArr);
        console.log("signalValue == ", signalValueArr);
        console.log(" this.weekOption ", this.weekOption);

        // this.weekOption.xAxis.data = labelArr;
        this.weekOption.series[0].data = signalValueArr;
        // 使用刚指定的配置项和数据显示图表。
        this.weekChart.setOption(this.weekOption);
      });
      // 使用刚指定的配置项和数据显示图表。
      this.weekChart.setOption(this.weekOption);
      window.addEventListener("resize", function() {
        setTimeout(function() {
          this.weekChart.resize();
        }, 100);
      });
    },
    charDay() {
      this.dayOption={
        tooltip: {
          trigger: "axis",
        },
        legend: {
          icon: "circle",
          top: "5%",
          left: "5%",
          itemWidth: 8,
          itemGap: 20,
          textStyle: {
            color: "#556677",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            // saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: true, //坐标轴两边留白
          data: this.hours,
          axisLabel: {
            //坐标轴刻度标签的相关设置。
            show: true,
            interval: 1, //设置为 1，表示『隔一个标签显示一个标签』
            // margin:15,
            // width:100,
            textStyle: {
              color: "#666",
              fontStyle: "normal",
              fontFamily: "微软雅黑",
              fontSize: 12,
            },
            formatter: function(params) {
              var newParamsName = "";
              var paramsNameNumber = params.length;
              var provideNumber = 5; //一行显示几个字
              var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
              // console.log(params);
              if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  var start = p * provideNumber;
                  var end = start + provideNumber;
                  if (p == rowNumber - 1) {
                    tempStr = params.substring(start, paramsNameNumber);
                  } else {
                    tempStr = params.substring(start, end) + "\n";
                  }
                  newParamsName += tempStr;
                }
              } else {
                newParamsName = params;
              }
              return newParamsName;
            },
            //rotate:50,
          },
          axisTick: {
            //坐标轴刻度相关设置。
            show: false,
          },
          axisLine: {
            //坐标轴轴线相关设置
            show: false,
            lineStyle: {
              color: "#E5E9ED",
              // opacity:0.2
            },
          },
          splitLine: {
            //坐标轴在 grid 区域中的分隔线。
            show: false,
            lineStyle: {
              color: "#E5E9ED",
              opacity: 0.1,
            },
          },
        },
        yAxis: [
          {
            type: "value",
            splitNumber: 5,
            axisLabel: {
              show: false,
              textStyle: {
                color: "#a8aab0",
                fontStyle: "normal",
                fontFamily: "微软雅黑",
                fontSize: 12,
              },
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#E5E9ED",
                type: "dashed",
                // 	opacity:0.1
              },
            },
          },
        ],
         
        series: [
          {
            name: "信号值",
            type: "bar",
            symbol: 'none',
            sampling: 'lttb',
            itemStyle: {
              normal: {
                color: "#12A9E6",
                lineStyle: {
                  color: "#12A9E6",
                  width: 1,
                },
                areaStyle: {
                  color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      offset: 0,
                      color: "rgba(255,255,255,0)",
                    },
                    {
                      offset: 1,
                      color: "rgba(118,231,248,1)",
                    },
                  ]),
                },
              },
            },
            data: [0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0],
          },
        ],
      };
      this.dayChart = echarts.init(document.getElementById("dayEcharts"));
      // this.weekChart = this.$echarts.init(this.$refs.weekEcharts);
      // 指定图表的配置项和数据
     

      this.autoHeight = document.documentElement.clientHeight || document.body.clientHeight;

      this.curHeight = this.autoHeight - 100; //减去页面上固定高度height

      this.dayChart.resize({ height: this.curHeight });

      //请求接口数据
      homeDeviceZigbeeMinuteLog(
        this.token,
        this.dayPage.currentPage,
        this.dayPage.pageSize,{
          deviceId:this.id,
          createTime:this.dayOfHour
        }
      ).then((res) => {
        const data = res.data.data.records;
        console.log(" this.data ", data);
        let labelArr = [];
        let signalValueArr = [];
        this.isEmpty = true;

         this.hours.forEach(itemDate =>{
            let signalValue = 0;
            data.filter( (item) =>{
              let tmpLab  = item.createTime.substring(11,13);
              console.log(" tmpDate ",itemDate );
              console.log(" tmpLab ",tmpLab );
              if(itemDate == parseInt(tmpLab)){
                  this.isEmpty = false;
                 signalValue = item.signalValue;
              }
            });
            signalValueArr.push(signalValue);
        });

        console.log("labelArr == ", labelArr);
        console.log("signalValueArr == ", signalValueArr);
        console.log(" this.dayOption ", this.dayOption);

        // this.dayOption.xAxis.data = labelArr;
        this.dayOption.series[0].data = signalValueArr;
        // 使用刚指定的配置项和数据显示图表。
        this.dayChart.setOption(this.dayOption);
      });
      // 使用刚指定的配置项和数据显示图表。
      this.dayChart.setOption(this.dayOption);
      window.addEventListener("resize", function() {
        setTimeout(function() {
          this.dayChart.resize();
        }, 100);
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
html {
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: Arial, Microsoft YaHei, SimHei, STHeiti, SimSun, STSong,
    sans-serif;
  color: #333;
  background-color: #fff;
  line-height: 1;
  font-weight: normal;
}

body,
ul,
p,
h1,
h2,
h3,
h4 {
  margin: 0;
}

a {
  color: #333;
  text-decoration: none;
}

a,
input,
button,
select {
  outline: none;
}

ul {
  padding: 0;
  list-style-type: none;
  list-style-position: outside;
}

h1,
h2,
h3,
h4 {
  font-family: inherit;
  font-size: inherit;
  font-weight: normal;
  line-height: inherit;
  text-align: inherit;
}

section,
button {
  display: block;
}

img {
  border: none;
  max-width: 100%;
}

/**统一约束规范**/
.fl {
  float: left;
}

.fr {
  float: right;
}

html {
  font-size: 100px;
}


.header {
  margin-top: 10px;
  height: 40px;
}

.nav {
  height: 64px;
  width: 100%;
  text-align: center;
}

.nav a {
  display: inline-block;
  width: 40px;
  height: 20px;
  line-height: 20px;
  background-color: #f1f1f1;
  /* border-radius: 20px; */
  font-size: 14px;
  border-radius: 50px;
}

.nav a:first-child {
  margin-right: 20px;
}

.nav a.cur {
  background-color: #5a8bdb;
  color: #fff;
}

.content .item {
  height: 40px;
  width: 100%;
  /* overflow: hidden; */
}

.content .item ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.content .item li {
  width: 40%;
  font-size: 12px;
  color: #333;
  text-align: center;
}

.content .item .count {
  font-size: 16px;
}

.content .item .count span {
  font-size: 16px;
}

.content .item .info {
  color: #999;
  margin-top: 5px;
}

.charts_box {
  margin-top: 10px;
  /* height: 10.15rem; */
  position: relative;
}
.tools {
  padding-top: 8px;
  font-size: 16px;
  color: #999;
}
.left_arrow {
  width: 15px;
  position: absolute;
  bottom: 16px;
  left: 0px;
    padding: 0px 5px
}
.right_arrow {
  width: 15px;
  position: absolute;
  bottom: 16px;
  right: 0px;
    padding: 0px 5px
}
.charts_box .title {
  font-size: 0.24rem;
  color: #666;
  padding: 0 0.5rem;
}

.charts_box .title .dot {
  display: inline-block;
  width: 0.16rem;
  height: 0.16rem;
  border-radius: 50%;
  background-color: #12a9e6;
  margin-right: 0.08rem;
  vertical-align: middle;
}

.charts {
  width: 100%;
  height: 100%;
}

.display-block {
  display: block;
}
.signo-img{
  width: 30px;
  height: 30px;
}
.none-box{
  position: absolute;
  width: calc(100% - 20px); 
  margin-top: 240px;
  z-index: 999;
}
.none-img{
 width: 40%;
}
</style>
