<template>

  <div id="app">
     <div class="Section0" style="layout-grid:15.6000pt;">
		<h1 align=center style="text-align:center;"><b><span
					style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:22.0000pt;mso-font-kerning:22.0000pt;">
					<font face="宋体">使用条款</font>
				</span></b><b><span
					style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:22.0000pt;mso-font-kerning:22.0000pt;">
					
				</span></b></h1>
		<h2
			style="text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<b><span
					style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">[
					<font face="黑体">重要提示</font>
					<font face="Arial">]</font>
				</span></b><b><span
					style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
					
				</span></b></h2>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<font face="宋体">在接受本协议之前</font>,<font face="宋体">请您仔细阅读本协议的全部内容</font>
				<font face="Calibri">(</font>
				<font face="宋体">特别是以粗体及</font>
				<font face="Calibri">/</font>
				<font face="宋体">或下划线标注的内容</font>
				<font face="Calibri">)</font>
				<font face="宋体">。如果您对本协议的条款有疑问的</font>
				<font face="Calibri">,</font>
				<font face="宋体">请通过相关业务部门进行询问</font>
				<font face="Calibri">,</font>
				<font face="宋体">我们将向您解释条款内容。如果您不同意本协议的任意内容</font>
				<font face="Calibri">,</font>
				<font face="宋体">或者无法准确理解我们对条款的解释</font>
				<font face="Calibri">,</font>
				<font face="宋体">请不要进行后续操作。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<font face="宋体">本软件许可及服务协议</font>(<font face="宋体">以下称&#8220;本协议&#8221;</font>
				<font face="Calibri">)</font>
				<font face="宋体">由您与</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<font face="宋体">北京欣智恒科技股份</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<font face="宋体">有限公司</font>(<font face="宋体">以下称&#8220;</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<font face="宋体">全宅卡威</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">&#8221;
				<font face="Calibri">)</font>
				<font face="宋体">共同签署</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<font face="宋体">本协议内容包括协议正文、附件以及所有</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<font face="宋体">全宅卡威</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<font face="宋体">已经发布的或未来可能发布的各类业务规范、活动规则、通知、公告等</font>(<font face="宋体">以下统称为&#8220;规则&#8221;</font>
				<font face="Calibri">),</font>
				<font face="宋体">包括但不限于</font>
				<font face="Calibri">:</font>
				<font face="宋体">《服务协议》、《法律声明和隐私权政策》等内容。所有规则为协议不可分割的组成部分</font>
				<font face="Calibri">,</font>
				<font face="宋体">与协议正文具有同等法律效力。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<font face="宋体">全宅卡威</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<font face="宋体">有权根据需要不时的制定、修改本协议和</font>/<font face="宋体">或各类规则。如有变更</font>
				<font face="Calibri">,</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<font face="宋体">全宅卡威</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<font face="宋体">将以网站公示的方式进行公告</font>,<font face="宋体">不再单独通知。变更后的协议和规则一经公布即自动生效</font>
				<font face="Calibri">,</font>
				<font face="宋体">成为本协议的一部分。如不同意相关变更</font>
				<font face="Calibri">,</font>
				<font face="宋体">服务商应当立即停止使用</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<font face="宋体">全宅卡威</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<font face="宋体">的服务</font>;<font face="宋体">如您继续使用该服务</font>
				<font face="Calibri">,</font>
				<font face="宋体">则视为对修改后的协议和规则不持异议并同意遵守。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<h2
			style="text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<b><span
					style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">1.
					<font face="黑体">定义</font></span></b><b><span
					style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
					
				</span></b></h2>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">1.1.
				<font face="宋体">许可软件</font>
				<font face="Calibri">:</font>
				<font face="宋体">是指由全宅卡威开发的</font>
				<font face="Calibri">,</font>
				<font face="宋体">供您从下载平台下载</font>
				<font face="Calibri">,</font>
				<font face="宋体">并仅限在相应系统移动终端中安装、使用的软件系统</font>
				<font face="Calibri">,</font>
				<font face="宋体">包括但不限于</font>
				<font face="Calibri">Android</font>
				<font face="宋体">、</font>
				<font face="Calibri">ios</font>
				<font face="宋体">版本的软件系统。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">1.2.
				<font face="宋体">服务</font>
				<font face="Calibri">:</font>
				<font face="宋体">由</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<font face="宋体">北京欣智恒科技股份</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<font face="宋体">有限公司</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<font face="宋体">为您提供的服务。您可以通过许可软件在移动终端使用服务。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<h2
			style="text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<b><span
					style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">2.</span></b><b><span
					style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
					<font face="黑体">服务内容</font>
				</span></b><b><span
					style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
					
				</span></b></h2>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">2.1.
				<font face="宋体">全宅卡威为您提供智能生活设备管理服务</font>
				<font face="Calibri">,</font>
				<font face="宋体">您可以通过全宅卡威控制连接智能终端</font>
				<font face="Calibri">,</font>
				<font face="宋体">并实现智能设备之间的联动。服务内容包含智能设备管理、场景联动、分析报告等功能</font>
				<font face="Calibri">,</font>
				<font face="宋体">这些功能服务可能根据用户需求的变化</font>
				<font face="Calibri">,</font>
				<font face="宋体">随着因服务版本不同、或服务提供方的单方判断而被优化或修改</font>
				<font face="Calibri">,</font>
				<font face="宋体">或因定期、不定期的维、或者暂缓提供。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<h2
			style="text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<b><span
					style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">3.</span></b><b><span
					style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
					<font face="黑体">授权范围</font>
				</span></b><b><span
					style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:黑体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
					
				</span></b></h2>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">3.1.
				<font face="宋体">全宅卡威就本软件给予您一项安装、使用、不可转让、不可转授权以及非独占性的许可。</font></span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">3.2.
				<font face="宋体">由于软件适配平台及终端限制</font>
				<font face="Calibri">,</font>
				<font face="宋体">您理解您仅可在获授权的系统平台及终端使用许可软件</font>
				<font face="Calibri">,</font>
				<font face="宋体">如您将许可软件安装在其他终端设备上</font>
				<font face="Calibri">(</font>
				<font face="宋体">包括台式电脑、手提电脑、或授权终端外的其他手持移动终端、电视机及机顶盒等</font>
				<font face="Calibri">),</font>
				<font face="宋体">可能会对您硬件或软件功能造成损害。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">3.3.
				<font face="宋体">您应该理解许可软件仅可用于非商业目的</font>
				<font face="Calibri">,</font>
				<font face="宋体">您不可为商业运营目的安装、使用、运行许可软件。如果需要进行商业性的复制、传播、销售</font>
				<font face="Calibri">,</font>
				<font face="宋体">例如软件预装和捆绑</font>
				<font face="Calibri">,</font>
				<font face="宋体">必须提前获得全宅卡威的书面授权和许可。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">3.4.
				<font face="宋体">全宅卡威会对许可软件及其相关功能不时进行变更、升级、修改或转移</font>
				<font face="Calibri">,</font>
				<font face="宋体">并会在许可软件系统中开发新的功能或其它服务。上述新的功能、软件服务如无独立协议的</font>
				<font face="Calibri">,</font>
				<font face="宋体">您仍可取得相应功能或服务的授权</font>
				<font face="Calibri">,</font>
				<font face="宋体">并可适用本协议。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<h2
			style="text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<b><span
					style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">4.</span></b><b><span
					style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
					<font face="黑体">第三方软件或服务</font>
				</span></b><b><span
					style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:黑体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
					
				</span></b></h2>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">4.1.
				<font face="宋体">许可软件可能使用或包含了由第三方提供的软件或服务</font>
				<font face="Calibri">(</font>
				<font face="宋体">以下称该等服务</font>
				<font face="Calibri">),</font>
				<font face="宋体">该等服务是为了向您提供便利而设置</font>
				<font face="Calibri">,</font>
				<font face="宋体">是取得该第三方的合法授权的。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">4.2.
				<font face="宋体">若全宅卡威使用了第三方提供的服务</font>
				<font face="Calibri">,</font>
				<font face="宋体">由于第三方为其软件或服务的提供者</font>
				<font face="Calibri">,</font>
				<font face="宋体">您使用该等服务时</font>
				<font face="Calibri">,</font>
				<font face="宋体">应另行与该第三方达成服务协议</font>
				<font face="Calibri">,</font>
				<font face="宋体">支付相应费用并承担可能的风险。您应理解全宅卡威并无权在本协议中授予您使用该等服务的任何权利</font>
				<font face="Calibri">,</font>
				<font face="宋体">也无权对该等服务提供任何形式的保证。全宅卡威无法对该等服务提供客户支持</font>
				<font face="Calibri">,</font>
				<font face="宋体">如果您需要获取支持</font>
				<font face="Calibri">,</font>
				<font face="宋体">您可直接与该第三方联系。因您使用该等服务引发的任何纠纷</font>
				<font face="Calibri">,</font>
				<font face="宋体">您可直接与该第三方协商解决。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">4.3.
				<font face="宋体">您理解许可软件仅在当前使用或包含该等服务</font>
				<font face="Calibri">,</font>
				<font face="宋体">全宅卡威无法保证许可软件将会永久地使用或包含该等服务</font>
				<font face="Calibri">,</font>
				<font face="宋体">也无法保证将来不会使用或包含该第三方的同类型或不同类型的软件或服务或其他第三方的软件或服务</font>
				<font face="Calibri">,</font>
				<font face="宋体">一旦全宅卡威在许可软件中使用或包含前述软件或服务</font>
				<font face="Calibri">,</font>
				<font face="宋体">相应的软件或服务同样适用本条约定。</font>
				<font face="Calibri">;</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<h2
			style="text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<b><span
					style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">5.</span></b><b><span
					style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
					<font face="黑体">服务规范</font>
				</span></b><b><span
					style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:黑体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
					
				</span></b></h2>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">5.1.
				<font face="宋体">您应该规范使用许可软件</font>
				<font face="Calibri">,</font>
				<font face="宋体">以下方式是违反使用规范的</font>
				<font face="Calibri">:</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">1)
				<font face="宋体">从事违反法律法规政策、破坏公序良俗、损害公共利益的行为。</font></span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">2)
				<font face="宋体">对许可软件及其中的相关信息擅自出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点</font>
				<font face="Calibri">,</font>
				<font face="宋体">借助许可软件发展与之有关的衍生产品、作品、服务、插件、外挂、兼容、互联等。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">3)
				<font face="宋体">通过非由全宅卡威及其关联公司开发、授权或认可的第三方兼容软件、系统登录或使用许可软件</font>
				<font face="Calibri">,</font>
				<font face="宋体">或针对许可软件使用非全宅卡威及其关联公司开发、授权或认证的插件和外挂。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">4)
				<font face="宋体">删除许可软件及其他副本上关于版权的信息、内容。修改、删除或避开应用产品中全宅卡威为保护知识产权而设置的任何技术措施。</font></span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">5)
				<font face="宋体">未经全宅卡威的书面同意</font>
				<font face="Calibri">,</font>
				<font face="宋体">擅自将许可软件出租、出借或再许可给第三方使用</font>
				<font face="Calibri">,</font>
				<font face="宋体">或在获得许可软件的升级版本的许可使用后</font>
				<font face="Calibri">,</font>
				<font face="宋体">同时使用多个版本的许可使用版本</font>
				<font face="Calibri">,</font>
				<font face="宋体">或分开转让。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">6)
				<font face="宋体">复制、反汇编、修改许可软件或其任何部分或制造其衍生作品</font>
				<font face="Calibri">;</font>
				<font face="宋体">对许可软件或者许可软件运行过程中释放在终端中的任何数据及许可软件运行过程中终端与服务器端的交互数据进行复制、修改、挂接运行或创作任何衍生作品</font>
				<font face="Calibri">,</font>
				<font face="宋体">包括使用插件、外挂或非经授权的第三方工具</font>
				<font face="Calibri">/</font>
				<font face="宋体">服务接入许可软件和相关系统等形式。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">7)
				<font face="宋体">进行任何危害信息网络安全的行为</font>
				<font face="Calibri">,</font>
				<font face="宋体">包括使用许可软件时以任何方式损坏或破坏许可软件或使其不能运行或超负荷或干扰第三方对许可软件的使用</font>
				<font face="Calibri">;</font>
				<font face="宋体">未经允许进入他人计算机系统并删除、修改、增加存储信息</font>
				<font face="Calibri">;</font>
				<font face="宋体">故意传播恶意程序或病毒以及其他破坏、干扰正常网络信息服务的行为。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">8)
				<font face="宋体">利用许可软件发表、传送、传播、储存侵害他人知识产权、商业秘密权等合法权利的内容</font>
				<font face="Calibri">,</font>
				<font face="宋体">或从事欺诈、盗用他人账户、资金等违法犯罪活动。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">9)
				<font face="宋体">通过修改或伪造许可软件运行中的指令、数据、数据包</font>
				<font face="Calibri">,</font>
				<font face="宋体">增加、删减、变动许可软件的功能或运行效果</font>
				<font face="Calibri">,</font>
				<font face="宋体">及</font>
				<font face="Calibri">/</font>
				<font face="宋体">或将具有上述用途的软件通过信息网络向公众传播或者运营。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">10)
				<font face="宋体">其他以任何不合法的方式、为任何不合法的目的、或以任何与本协议不一致的方式使用许可软件。</font></span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">5.2.
				<font face="宋体">您理解并同意</font>
				<font face="Calibri">:</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">1)
				<font face="宋体">全宅卡威会对您是否涉嫌违反上述使用规范做出认定</font>
				<font face="Calibri">,</font>
				<font face="宋体">并根据认定结果中止、终止对您的使用许可或采取其他依约可采取的限制措施。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">2)
				<font face="宋体">对于您使用许可软件时发布的涉嫌违法或涉嫌侵犯他人合法权利或违反本协议的信息</font>
				<font face="Calibri">,</font>
				<font face="宋体">全宅卡威会直接予以删除。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">3)
				<font face="宋体">对于您违反上述使用规范的行为对任意第三方造成损害的</font>
				<font face="Calibri">,</font>
				<font face="宋体">您需要以自己的名义独立承担法律责任</font>
				<font face="Calibri">,</font>
				<font face="宋体">并应确保全宅卡威免于因此产生损失或增加费用。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">4)
				<font face="宋体">如您违反有关法律或者本协议之规定</font>
				<font face="Calibri">,</font>
				<font face="宋体">使全宅卡威遭受任何损失</font>
				<font face="Calibri">,</font>
				<font face="宋体">或受到任何第三方的索赔</font>
				<font face="Calibri">,</font>
				<font face="宋体">或受到任何行政管理部门的处罚</font>
				<font face="Calibri">,</font>
				<font face="宋体">您应当赔偿全宅卡威因此造成的损失及</font>
				<font face="Calibri">(</font>
				<font face="宋体">或</font>
				<font face="Calibri">)</font>
				<font face="宋体">发生的费用</font>
				<font face="Calibri">,</font>
				<font face="宋体">包括合理的律师费用、调查取证费用。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<h2
			style="text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<b><span
					style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">6.</span></b><b><span
					style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
					<font face="黑体">信息内容规范</font>
				</span></b><b><span
					style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:黑体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
					
				</span></b></h2>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">6.1.
				<font face="宋体">您承诺不会利用本服务进行任何违法或不当的活动</font>
				<font face="Calibri">,</font>
				<font face="宋体">包括但不限于下列行为</font>
				<font face="Calibri">:</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">6.1.1.
				<font face="宋体">上载、传送或分享含有下列内容之一的信息</font>
				<font face="Calibri">:</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">1)
				<font face="宋体">反对宪法所确定的基本原则的</font>
				<font face="Calibri">;</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">2)
				<font face="宋体">危害国家安全</font>
				<font face="Calibri">,</font>
				<font face="宋体">泄露国家秘密</font>
				<font face="Calibri">,</font>
				<font face="宋体">颠覆国家政权</font>
				<font face="Calibri">,</font>
				<font face="宋体">破坏国家统一的</font>
				<font face="Calibri">;</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">3)
				<font face="宋体">损害国家荣誉和利益的</font>
				<font face="Calibri">;</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">4)
				<font face="宋体">动民族仇恨、民族歧视、破坏民族团结的</font>
				<font face="Calibri">;</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">5)
				<font face="宋体">破坏国家宗教政策</font>
				<font face="Calibri">,</font>
				<font face="宋体">宣扬邪教和封建迷信的</font>
				<font face="Calibri">;</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">6)
				<font face="宋体">散布谣言</font>
				<font face="Calibri">,</font>
				<font face="宋体">扰乱社会秩序</font>
				<font face="Calibri">,</font>
				<font face="宋体">破坏社会稳定的</font>
				<font face="Calibri">;</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">7)
				<font face="宋体">散布淫积、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的</font>
				<font face="Calibri">;</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">8)
				<font face="宋体">侮辱或者诺谢他人</font>
				<font face="Calibri">,</font>
				<font face="宋体">侵害他人合法权利的</font>
				<font face="Calibri">;</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">9)
				<font face="宋体">含有虚假、诈骗、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、狠裹、或其它道德上令人反感的内容</font>
				<font face="Calibri">;</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">10)
				<font face="宋体">含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的</font>
				<font face="Calibri">;</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">6.1.2.
				<font face="宋体">冒充任何人或机构</font>
				<font face="Calibri">,</font>
				<font face="宋体">或以虚伪不实的方式陈述或谎称与任何人或机构有关</font>
				<font face="Calibri">;</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">6.1.3.
				<font face="宋体">伪造标题或以其他方式操控识别资料</font>
				<font face="Calibri">,</font>
				<font face="宋体">使人误认为该内容为淘宝或其关联公司所传送</font>
				<font face="Calibri">;</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">6.1.4.
				<font face="宋体">将依据任何法律或合约或法定关系</font>
				<font face="Calibri">(</font>
				<font face="宋体">例如由于雇佣关系和依据保密合约所得知或揭露之内部资料、专属及机密资料</font>
				<font face="Calibri">)</font>
				<font face="宋体">知悉但无权传送之任何内容加以上载、传送或分享</font>
				<font face="Calibri">;</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">6.1.5.
				<font face="宋体">将涉嫌侵害他人权利</font>
				<font face="Calibri">(</font>
				<font face="宋体">包括但不限于著作权、专利权、商标权、商业秘密等知识产权</font>
				<font face="Calibri">)</font>
				<font face="宋体">之内容上载、传送或分享</font>
				<font face="Calibri">;</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">6.1.6.
				<font face="宋体">跟踪或以其它方式骚扰他人</font>
				<font face="Calibri">,</font>
				<font face="宋体">或通过本服务向好友或其他用户发送大量信息</font>
				<font face="Calibri">;</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">6.1.7.
				<font face="宋体">
					将任何广告、推广信息、促销资料、&#8220;垃圾邮件&#8221;、&#8220;滥发信件&#8221;、&#8220;连锁信件&#8221;、&#8220;直销&#8221;或其它任何形式的劝诱资料加以上载、传送或分享
				</font>
				<font face="Calibri">;</font>
				<font face="宋体">供前述目的使用的专用区域或专用功能除外</font>
				<font face="Calibri">;</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">6.1.8.
				<font face="宋体">因本服务并非为某些特定目的而设计</font>
				<font face="Calibri">,</font>
				<font face="宋体">您不可将本服务用于包括但不限于核设施、军事用途、医疗设施、交通通讯等重要领域。如果因为软件或服务的原因导致上述操作失败而带来的人员伤亡、财产损失和环境破坏等</font>
				<font face="Calibri">,</font>
				<font face="宋体">淘宝不承担法律责任</font>
				<font face="Calibri">;</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">6.1.9.
				<font face="宋体">进行任何超出正常的好友或用户之间内部或外部信息沟通、交流等目的的行为</font>
				<font face="Calibri">;</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">6.1.10.
				<font face="宋体">出于超出正常好友或用户之间内部或外部信息沟通、交流等目的</font>
				<font face="Calibri">(</font>
				<font face="宋体">包括但不限于为发送广告、垃圾、骚扰或违法违规等信息的目的</font>
				<font face="Calibri">),</font>
				<font face="宋体">通过自己添加或诱导他人添加等任何方式使自己与其他用户形成好友关系</font>
				<font face="Calibri">(</font>
				<font face="宋体">好友关系包括但不限于单向好友关系和双向好友关系</font>
				<font face="Calibri">,</font>
				<font face="宋体">下同</font>
				<font face="Calibri">);</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">6.1.11.
				<font face="宋体">通过本服务向好友或其他用户发送大量信息</font>
				<font face="Calibri">;</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">6.1.12.
				<font face="宋体">违反遵守法律法规、社会主义制度、国家利益、公民合法利益、公共秩序、社会道德风尚和信息真实性等&#8220;七条底线&#8221;要求的行为</font>
				<font face="Calibri">;</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">6.1.13.
				<font face="宋体">从事任何违反中国法律、法规、规章、政策及规范性文件的行为。</font></span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<h2
			style="text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<b><span
					style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">7.
					<font face="黑体">知识产权声明</font></span></b><b><span
					style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
					
				</span></b></h2>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">7.1.
				<font face="宋体">全宅卡威为此软件的知识产权持有人。版权、商标、专利、商业机密等与该软件相关的所有知识产权</font>
				<font face="Calibri">,</font>
				<font face="宋体">以及与该软件相关的所有资讯</font>
				<font face="Calibri">(</font>
				<font face="宋体">包括但不限于文字、图像、音讯、视讯、图形、界面设计、配置、数据或电子文件等</font>
				<font face="Calibri">)</font>
				<font face="宋体">均受本地法律及法规以及相应国际协定保护。全宅卡威拥有上述知识产权。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">7.2.
				<font face="宋体">未经全宅卡威事先书面同意</font>
				<font face="Calibri">,</font>
				<font face="宋体">用户不得出于任何商业或非商业目的</font>
				<font face="Calibri">,</font>
				<font face="宋体">独立或允许任何第三方擅自使用或转让任何上述知识产权。全宅卡威保留就该等行为追究法律责任的权利。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<h2
			style="text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<b><span
					style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">8.</span></b><b><span
					style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
					<font face="黑体">无担保和责任制</font>
				</span></b><b><span
					style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:黑体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
					
				</span></b></h2>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">8.1.
				<font face="宋体">除法律法规有明确规定外</font>
				<font face="Calibri">,</font>
				<font face="宋体">全宅卡威将尽最大努力确保许可软件及其所涉及的技术及信息安全、有效、准确、可靠</font>
				<font face="Calibri">,</font>
				<font face="宋体">但受限于现有技术</font>
				<font face="Calibri">,</font>
				<font face="宋体">您理解全宅卡威不能对此进行担保。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">8.2.
				<font face="宋体">您理解</font>
				<font face="Calibri">,</font>
				<font face="宋体">对于不可抗力及第三方原因导致的您的直接或间接损失</font>
				<font face="Calibri">,</font>
				<font face="宋体">全宅卡威无法承担责任。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">8.3.
				<font face="宋体">由于您因下述任一情况所引起或与此有关的人身伤害或附带的、间接的损害赔偿</font>
				<font face="Calibri">,</font>
				<font face="宋体">包括但不限于利润损失、资料损失、业务中断的损害赔偿或其它商业损害赔偿或损失</font>
				<font face="Calibri">,</font>
				<font face="宋体">需由您自行承担</font>
				<font face="Calibri">:</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">1)
				<font face="宋体">使用或未能使用许可软件</font>
				<font face="Calibri">;</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">2)
				<font face="宋体">第三方未经批准的使用许可软件或更改您的数据</font>
				<font face="Calibri">;</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">3)
				<font face="宋体">使用许可软件进行的行为产生的费用及损失</font>
				<font face="Calibri">;</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">4)
				<font face="宋体">您对许可软件的误解</font>
				<font face="Calibri">;</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">5)
				<font face="宋体">非因全宅卡威的原因而引起的与许可软件有关的其它损失。</font></span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">8.4.
				<font face="宋体">非经全宅卡威或全宅卡威授权开发并正式发布的其它任何由许可软件衍生的软件均属非法</font>
				<font face="Calibri">,</font>
				<font face="宋体">下载、安装、使用此类软件</font>
				<font face="Calibri">,</font>
				<font face="宋体">可能导致不可预知的风险</font>
				<font face="Calibri">,</font>
				<font face="宋体">由此产生的法律责任与纠纷与全宅卡威无关</font>
				<font face="Calibri">,</font>
				<font face="宋体">全宅卡威有权中止、终止使用许可和</font>
				<font face="Calibri">/</font>
				<font face="宋体">或其他一切服务。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">8.5.
				<font face="宋体">全宅卡威可能会根据相关的食材营养、平衡膳食、运动等信息</font>
				<font face="Calibri">,</font>
				<font face="宋体">向您推荐对应的食材、食物、运动目标等信息</font>
				<font face="Calibri">,</font>
				<font face="宋体">但您知悉并同意</font>
				<font face="Calibri">,</font>
				<font face="宋体">该等信息但仅供参考并不作为认为专业或职业意见、结论</font>
				<font face="Calibri">,</font>
				<font face="宋体">同时您需要自行评估合理性以及做好运动过程中的自身防护。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<h2
			style="text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<b><span
					style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">9.</span></b><b><span
					style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
					<font face="黑体">知识产权</font>
				</span></b><b><span
					style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:黑体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
					
				</span></b></h2>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">9.1.
				<font face="宋体">全宅卡威拥有许可软件的软件著作权、专利、商标、商业秘密以及其他相关的知识产权</font>
				<font face="Calibri">,</font>
				<font face="宋体">包括与许可软件有关的各种文档资料及其中的知识产权。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">9.2.
				<font face="宋体">许可软件的相关标识、</font>
				<font face="Calibri">logo</font>
				<font face="宋体">和名称属于全宅卡威及全宅卡威的关联公司的知识产权</font>
				<font face="Calibri">,</font>
				<font face="宋体">并受到相关法律法规的保护。未经全宅卡威明确授权</font>
				<font face="Calibri">,</font>
				<font face="宋体">您不得复制、模仿、使用或发布上述标识</font>
				<font face="Calibri">,</font>
				<font face="宋体">也不得修改或删除应用产品中体现全宅卡威及其关联公司的任何标识或身份信息。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">9.3.
				<font face="宋体">未经全宅卡威及全宅卡威的关联公司事先书面同意</font>
				<font face="Calibri">,</font>
				<font face="宋体">您不得为任何营利性或非营利性的目的自行实施、利用、转让或许可任何第三方实施、利用、转让上述知识产权。。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<h2
			style="text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<b><span
					style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">10.</span></b><b><span
					style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
					<font face="黑体">协议终止及违约责任</font>
				</span></b><b><span
					style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:黑体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
					
				</span></b></h2>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">10.1.
				<font face="宋体">您应理解按授权范围使用许可软件、尊重软件及软件包含内容的知识产权、按规范使用软件、按本协议约定履行义务是您获取全宅卡威授权使用许可软件的前提</font>
				<font face="Calibri">,</font>
				<font face="宋体">如您严重违反本协议</font>
				<font face="Calibri">,</font>
				<font face="宋体">全宅卡威将终止使用许可。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">10.2.
				<font face="宋体">您对软件的使用有赖于全宅卡威关联公司为您提供的配套服务</font>
				<font face="Calibri">,</font>
				<font face="宋体">您违反与全宅卡威或全宅卡威关联公司的条款、协议、规则、通告等相关规定</font>
				<font face="Calibri">,</font>
				<font face="宋体">而被上述任一网站终止提供服务的</font>
				<font face="Calibri">,</font>
				<font face="宋体">可能导致您无法正常使用许可软件</font>
				<font face="Calibri">,</font>
				<font face="宋体">全宅卡威有权终止使用许可。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">10.3.
				<font face="宋体">您理解出于维护平台秩序及保护消费者权益的目的</font>
				<font face="Calibri">,</font>
				<font face="宋体">如果您向全宅卡威及</font>
				<font face="Calibri">(</font>
				<font face="宋体">或</font>
				<font face="Calibri">)</font>
				<font face="宋体">全宅卡威的关联公司作出任何形式的承诺</font>
				<font face="Calibri">,</font>
				<font face="宋体">且相关公司已确认您违反了该承诺并通知全宅卡威依据您与其相关约定进行处理的</font>
				<font face="Calibri">,</font>
				<font face="宋体">则全宅卡威可按您的承诺或协议约定的方式对您的使用许可及其他全宅卡威可控制的权益采取限制措施</font>
				<font face="Calibri">,</font>
				<font face="宋体">包括中止或终止对您的使用许可。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">10.4.
				<font face="宋体">一旦您违反本协议或与全宅卡威签订的其他协议的约定</font>
				<font face="Calibri">,</font>
				<font face="宋体">全宅卡威可通知全宅卡威关联公司</font>
				<font face="Calibri">,</font>
				<font face="宋体">要求其对您的权益采取限制措施</font>
				<font face="Calibri">,</font>
				<font face="宋体">包括要求关联公司中止、终止对您提供部分或全部服务</font>
				<font face="Calibri">,</font>
				<font face="宋体">且在其经营或实际控制的网站依法公示您的违约情况。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">10.5.
				<font face="宋体">许可软件由您自下载平台下载取得</font>
				<font face="Calibri">,</font>
				<font face="宋体">您需要遵守下载平台、系统平台、终端厂商对您使用许可软件方式与限制的约定</font>
				<font face="Calibri">,</font>
				<font face="宋体">如果上述第三方确认您违反该约定需要全宅卡威处理的</font>
				<font face="Calibri">,</font>
				<font face="宋体">全宅卡威可能会因第三方要求终止对您的使用许可。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">10.6.
				<font face="宋体">在本使用许可终止时</font>
				<font face="Calibri">,</font>
				<font face="宋体">您应停止对许可软件的使用行为</font>
				<font face="Calibri">,</font>
				<font face="宋体">并销毁许可软件的全部副本。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">10.7.
				<font face="宋体">如您违反本协议规定的条款</font>
				<font face="Calibri">,</font>
				<font face="宋体">给全宅卡威或其他用户造成损失</font>
				<font face="Calibri">,</font>
				<font face="宋体">您必须承担全部的赔偿责任。如全宅卡威承担了上述责任</font>
				<font face="Calibri">,</font>
				<font face="宋体">则您同意赔偿全宅卡威的相关支出和损失</font>
				<font face="Calibri">,</font>
				<font face="宋体">包括合理的律师费用。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<h2
			style="text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<b><span
					style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">1</span></b><b><span
					style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">1</span></b><b><span
					style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">.
					<font face="黑体">管辖法律和可分割性</font></span></b><b><span
					style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
					
				</span></b></h2>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">11.1.
				<font face="宋体">本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律</font>
				<font face="Calibri">,</font>
				<font face="宋体">如无相关法律规定的</font>
				<font face="Calibri">,</font>
				<font face="宋体">则应参照通用国际商业惯例和</font>
				<font face="Calibri">(</font>
				<font face="宋体">或</font>
				<font face="Calibri">)</font>
				<font face="宋体">行业惯例。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">11.2.
				<font face="宋体">因本协议产生或与本协议有关的争议</font>
				<font face="Calibri">,</font>
				<font face="宋体">您可与全宅卡威以友好协商的方式予以解决或提交被告所在地人民法院予以裁决。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">11.3.
				<font face="宋体">本协议任何条款被有管辖权的人民法院裁定为无效</font>
				<font face="Calibri">,</font>
				<font face="宋体">不应影响其他条款或其任何部分的效力</font>
				<font face="Calibri">,</font>
				<font face="宋体">您与全宅卡威仍应善意履行。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<h2
			style="text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<b><span
					style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">1</span></b><b><span
					style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">2</span></b><b><span
					style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">.
					<font face="黑体">其他</font></span></b><b><span
					style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
					
				</span></b></h2>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">12.1.
				<font face="宋体">全宅卡威可能根据业务调整而变更向您提供软件服务的主体</font>
				<font face="Calibri">,</font>
				<font face="宋体">变更后的主体与您共同履行本协议并向您提供服务</font>
				<font face="Calibri">,</font>
				<font face="宋体">以上变更不会影响您本协议项下的权益。发生争议时</font>
				<font face="Calibri">,</font>
				<font face="宋体">您可根据您具体使用的服务及对您权益产生影响的具体行为对象确定与您履约的主体及争议相对方。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal
			style="text-indent:21.0000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">12.2.
				<font face="宋体">本协议的所有标题仅仅是为了醒目及阅读方便</font>
				<font face="Calibri">,</font>
				<font face="宋体">本身并没有实际涵义</font>
				<font face="Calibri">,</font>
				<font face="宋体">不能作为解释本协议涵义的依据。</font>
			</span><span
				style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				
			</span></p>
		<p class=MsoNormal align=right
			style="text-indent:21.1000pt;mso-char-indent-count:2.0000;text-autospace:ideograph-numeric;mso-pagination:none;text-align:right;line-height:18.0000pt;mso-line-height-rule:exactly;">
			<b><span
					style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
					<font face="宋体">全宅卡威</font>
				</span></b><b><span
					style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
					
				</span></b></p>
	</div>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
.agr-box {
  padding: 10px;
}

@font-face {
  font-family: "Times New Roman";
}

@font-face {
  font-family: "宋体";
}

@font-face {
  font-family: "Wingdings";
}

@font-face {
  font-family: "DejaVu Sans";
}

@font-face {
  font-family: "Arial";
}

@font-face {
  font-family: "黑体";
}

@font-face {
  font-family: "Calibri";
}

@font-face {
  font-family: "微软雅黑";
}

@list l0:level1 {
  mso-level-number-format: bullet;
  mso-level-suffix: tab;
  mso-level-text: "";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 21pt;
  text-indent: -21pt;
  font-family: Wingdings;
}

p.MsoNormal {
  mso-style-name: 正文;
  mso-style-parent: "";
  margin: 0pt;
  margin-bottom: 0.0001pt;
  mso-pagination: none;
  text-align: justify;
  text-justify: inter-ideograph;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: "Times New Roman";
  font-size: 10.5pt;
  mso-font-kerning: 1pt;
}

h2 {
  mso-style-name: "标题 2";
  mso-style-noshow: yes;
  mso-style-next: 正文;
  margin-top: 13pt;
  margin-bottom: 13pt;
  mso-para-margin-top: 0gd;
  mso-para-margin-bottom: 0gd;
  page-break-after: avoid;
  mso-pagination: lines-together;
  text-align: justify;
  text-justify: inter-ideograph;
  mso-outline-level: 2;
  line-height: 172%;
  font-family: Arial;
  mso-fareast-font-family: 黑体;
  mso-bidi-font-family: "Times New Roman";
  font-weight: bold;
  font-size: 16pt;
  mso-font-kerning: 1pt;
}

h3 {
  mso-style-name: "标题 3";
  mso-style-noshow: yes;
  mso-style-next: 正文;
  margin-top: 13pt;
  margin-bottom: 13pt;
  mso-para-margin-top: 0gd;
  mso-para-margin-bottom: 0gd;
  page-break-after: avoid;
  mso-pagination: lines-together;
  text-align: justify;
  text-justify: inter-ideograph;
  mso-outline-level: 3;
  line-height: 172%;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: "Times New Roman";
  font-weight: bold;
  font-size: 16pt;
  mso-font-kerning: 1pt;
}

span.10 {
  font-family: "Times New Roman";
}

span.15 {
  font-family: "Times New Roman";
  color: rgb(0, 0, 255);
  text-decoration: underline;
  text-underline: single;
}

p.MsoCommentText {
  mso-style-name: 批注文字;
  margin: 0pt;
  margin-bottom: 0.0001pt;
  mso-pagination: none;
  text-align: left;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: "Times New Roman";
  font-size: 10.5pt;
  mso-font-kerning: 1pt;
}

span.msoIns {
  mso-style-type: export-only;
  mso-style-name: "";
  text-decoration: underline;
  text-underline: single;
  color: blue;
}

span.msoDel {
  mso-style-type: export-only;
  mso-style-name: "";
  text-decoration: line-through;
  color: red;
}

table.MsoNormalTable {
  mso-style-name: 普通表格;
  mso-style-parent: "";
  mso-style-noshow: yes;
  mso-tstyle-rowband-size: 0;
  mso-tstyle-colband-size: 0;
  mso-padding-alt: 0pt 5.4pt 0pt 5.4pt;
  mso-para-margin: 0pt;
  mso-para-margin-bottom: 0.0001pt;
  mso-pagination: widow-orphan;
  font-family: "Times New Roman";
  font-size: 10pt;
  mso-ansi-language: #0400;
  mso-fareast-language: #0400;
  mso-bidi-language: #0400;
}
@page {
  mso-page-border-surround-header: no;
  mso-page-border-surround-footer: no;
}
@page Section0 {
  margin-top: 72pt;
  margin-bottom: 72pt;
  margin-left: 90pt;
  margin-right: 90pt;
  size: 595.3pt 841.9pt;
  layout-grid: 15.6pt;
}
div.Section0 {
  page: Section0;
}
</style>
