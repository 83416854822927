import request from '@/router/axios';

export const getDeviceLog = (token,data) => {
  return request({
    token,
    url: `/api/front/iot-customer/device/log/chartData`,
    method: 'POST',
    data
  })
}

export const getBarChartData = (token,data) => {
  return request({
    token,
    url: `/api/front/iot-customer/device/log/barChartData`,
    method: 'POST',
    data
  })
}

export const appLogList = (token,data) => {
  return request({
    token,
    url: `/api/front/iot-customer/device/log/appLogList`,
    method: 'POST',
    data
  })
}

export const sceneLogList = (token,data) => {
  return request({
    token,
    url: `/api/front/iot-customer/device/log/sceneLogList`,
    method: 'POST',
    data
  })
}

export function getDeviceDetail(token,id) {
  return request({
    token,
    url: `/api/front/iot-customer/customer/device/attribute/${id}`,
    method: 'GET'
  })
}
