import request from '@/router/axios';

//获取时管理员的家庭
export const getList = (token) => {
  return request({
    token,
    url: `/api/front/iot-customer/family/list`,
    method: 'get',
    params: {}
  })
}
//获取所有的家庭信息
export const getHomesList = (token) => {
  return request({
    token,
    url: `/api/front/iot-customer/family/info/list`,
    method: 'get',
    params: {}
  })
}

export const getDetail = (id) => {
  return request({
    url: '/api/front/iot-customer/homes_info/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/api/front/iot-customer/homes_info/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: '/api/front/iot-customer/homes_info/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: '/api/front/iot-customer/homes_info/submit',
    method: 'post',
    data: row
  })
}

