import request from '@/router/axios';

export const getList = (token,data) => {
  return request({
    token,
    url: `/api/front/iot-customer/help_info/listPage`,
    method: 'POST',
    data
  })
}

export const getDetail = (token,id) => {
  return request({
    token,
    url: '/api/front/cs-helpInfo/help_info/detail',
    method: 'get',
    params: {
      id
    }
  })
}
