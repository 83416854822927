<template>
  <div class="log-box">
    <div class="top-bar">
      <div class="btn">账号密码登录</div>
    </div>
    <div>
      <el-form ref="form" :model="model" :rules="validatorRules">
        <el-form-item label="账号" prop="username">
          <el-input v-model="model.username" placeholder="请输入帐户名"/>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="model.password" type="password" placeholder="请输入密码"/>
        </el-form-item>
        <el-form-item>
          <el-button class="btn" @click="handleLogin">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {tmLogin} from '@/api/tm/tm.js'

import 'moment/locale/zh-cn'

export default {
  name: "tmLogin",

  data() {
    return {
      model: {
        username: '',
        password: '',
      },
      validatorRules: {
        username: [{
            required: true, message: '请输入账户!', validator: 'click'
        }],
        password: [{
          required: true, message: '请输入密码!', validator: 'click'
        }]
      }
    };
  },
  created: function () {
  },
  mounted: function () {
  },
  methods: {
    handleLogin() {
      let url = "/api/dmp/api/oauth/userlogin?client_id="+this.$route.query.clientId
          + "&response_type="+this.$route.query.responseType
          +"&redirect_uri="+encodeURIComponent(this.$route.query.redirectURL,'GBK')
          +"&state="+this.$route.query.state
          +"&share_target="+this.$route.query.shareTarget;
      tmLogin(
          url,
          this.model
      ).then((res) => {
        console.log(" data ", res.data.data);
        if(res.data.data.success){
          window.location.href = res.data.data.msg;
        }else{
          alert(res.data.data.msg);
        }
      });
    },
  },
};
</script>

<style scoped>

.top-bar {
  /*position: fixed;*/
  width: calc(100% - 20px);;
  background-color: white;
  z-index: 999;
  /* border-bottom: 1px solid #ebeef5; */
}

.top-bar .btn {
  text-align: center;
  width: 100%;
  float: left;
  height: 40px;
  line-height: 40px;
  font-weight: 400;
  color: #333333;
}

.log-box {
  text-align: left;
}
</style>
