<template>
  <div class="invent">
    <div>
      <span class="logo-box">
        <img src="img/logo.png" />
      </span>
      <span class="name-box">
        <div class="name">全宅卡威</div>
        <div class="sub-name">全宅卡威陪伴你，让智能生活更美好！</div>
      </span>
    </div>
    <div class="content-box">
      <div class="tel-box">{{id}}邀请你</div>
      <div class="sub-content">加入Ta的家庭，控制和查看Ta的智能家居设备</div>
      <div class="sub-content">请前往 APP“我的>消息中心>邀请”开启此功能</div>
    </div>
    <div class="big-img-box">
      <img src="img/pic1.png" />
    </div>
    <div class="btn" @click="iosDownload()">IOS注册</div>
    <div class="btn" @click="androidDownload()">Android注册</div>
  </div>
</template>

<script>

export default {
  name: "invent",
  data() {
    return {
      calendarShow: true,
      minDate: new Date(), //设置过期时间为当天
      show: false,
      date: "",
      token:"",

      result: ''
    };
  },
  created: function() {
    console.log(" this.$router ",this.$route);
    this.id = this.$route.query.id;
    console.log(" this.id ",this.id);
  },
  components: {},
  methods: {
    iosDownload () {
      window.open("https://apps.apple.com/cn/app/%E5%85%A8%E5%AE%85%E5%8D%A1%E5%A8%81/id1571229850", '_blank');
      return "ios";
    },
    androidDownload () {
      window.open("http://download.yun361.cn/%E5%85%A8%E5%AE%85%E5%8D%A1%E5%A8%81.apk", '_blank');
      return "andriod";
    }

  },
};
</script>
<style scoped>
.invent {
  margin-top: 20px;
  text-align: left;
  padding: 10px;
}
.logo-box {
  float: left;
  margin-right: 10px;
}
.logo-box img {
  width: 50px;
  height: 50px;
  text-align: center;
}
.name-box {
}
.content-box {
  margin: 50px 0;
  text-align: center;
}
.name {
  padding-top: 4px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 23px;
}
.sub-name {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 19px;
}

.tel-box {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 28px;
}

.sub-content {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 17px;
}
.big-img-box {
  text-align: center;
  width: 100%;
}
.big-img-box img {
  width: 100%;
}
.btn {
  margin-top: 40px;
  text-align: center;
  width: 300px;
  height: 40px;
  background: #5a8bdb;
  border-radius: 24px;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 40px;
  margin: 20px auto;
}
</style>
