import request from '@/router/axios';

export const tmLogin = (url,row) => {
  return request({
    url: url,
    method: 'post',
    data: row
  })
}

