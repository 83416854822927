<template>
  <div class="hello">
    <div class="none-box" v-if="isEmpty">
       <img src="img/none.png" class="none-img" >
       <div class="text">暂无数据</div>
    </div>
          <!-- {{token.length}}
    {{id}} -->

    <header class="header">
      <nav class="nav">
        <a v-on:click.stop="doDay" :class="{ cur: cur == 'day' }">日</a>
        <a v-on:click.stop="doWeek" :class="{ cur: cur == 'week' }">周</a>
      </nav>
    </header>

    <section class="content" v-if="cur == 'day'">
      <div class="charts_box">
        <!-- <div class="title"><i class="dot"></i>{{dayOfHour}}</div> -->
        <div
          class="charts"
          id="dayEcharts"
          :style="{ height: curHeight }"
        ></div>
        <div class="tools">
          <div class="left_arrow" @click="dayBefor()">
            <img src="img/left.png" />
          </div>
          <div class="right_arrow" @click="dayNext()">
            <img src="img/right.png" />
          </div>
        </div>
      </div>
    </section>
  
    <section class="content" v-if="cur == 'week'">
        <div class="charts_box">
          <!-- <div class="title"><i class="dot"></i>最新用电量</div> -->
          <div
            class="charts"
            id="weekEcharts"
            :style="{ height: curHeight }"
          ></div>
          <div class="tools">
            <div class="left_arrow" @click="weekBefor()">
              <img src="img/left.png" />
            </div>
            <div class="right_arrow" @click="weekNext()">
              <img src="img/right.png" />
            </div>
          </div>
        </div>
    </section>
 
   
  </div>
</template>

<script>
import moment from 'moment'
import { getList as devicePowerHourLog  } from "@/api/devicePowerHourLog/device_power_hour_log";
import { getList as devicePowerWeekLog  } from "@/api/devicePowerWeekLog/device_power_week_log";

// 引入 ECharts 主模块
let echarts = require("echarts/lib/echarts");
// 引入折线图/柱状图等组件
require("echarts/lib/chart/line");
require("echarts/lib/chart/bar");
require("echarts/lib/chart/radar");
require("echarts/lib/chart/gauge");
// 引入提示框和title组件，图例
require("echarts/lib/component/toolbox");
require("echarts/lib/component/grid");
require("echarts/lib/component/tooltip");
require("echarts/lib/component/title");
require("echarts/lib/component/legend");
require("echarts/lib/component/legendScroll"); //图例滚动
require("echarts/lib/component/dataZoom");

export default {
  name: "power",

  data() {
    return {
      cur: 'day',
      isEmpty:true,
      id:'',
      token:"",
      curHeight: 100,
      weekChart: null,
      weeKOption: {},
      dayChart: null,
      dayOption: {},
      hours:["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12","13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "00"],
      weekPage: {
        pageSize: 7,
        currentPage: 1,
        total: 0,
      },
      dayPage: {
        pageSize: 24,
        currentPage: 1,
        total: 0,
      },
      weekDayArray:[],
      weekOfday:'',
      start:'',
      end:'',
      dayOfHour:'',
      day:''
    };
  },
  props: {
    msg: String,
  },
  created: function() {
    this.dayOfHour = moment().format('YYYY-MM-DD');
    this.getToken();
  },
  mounted: function() {
    
  },
  methods: {
    getWeek(weekOfdayValue){
      this.start = moment().subtract(weekOfdayValue, 'days').format('YYYY-MM-DD 00:00:00') // 周一日期
      this.end = moment().add(7 - weekOfdayValue - 1, 'days').format('YYYY-MM-DD 23:59:59') // 周日日期
      this.weekDayArray = [];
      this.weekDayArray.push(moment(this.start).format("MM/DD周日"))
      this.weekDayArray.push(moment(this.start).subtract(-1, 'days').format("MM/DD"))
      this.weekDayArray.push(moment(this.start).subtract(-2, 'days').format("MM/DD"))
      this.weekDayArray.push(moment(this.start).subtract(-3, 'days').format("MM/DD"))
      this.weekDayArray.push(moment(this.start).subtract(-4, 'days').format("MM/DD"))
      this.weekDayArray.push(moment(this.start).subtract(-5, 'days').format("MM/DD"))
      this.weekDayArray.push(moment(this.end).format("MM/DD周六"))
      console.log(" weekOfday " +this.weekOfday);
      console.log(" start " +this.start);
      console.log(" end " +this.end);
      console.log(" this.weekDayArray " +this.weekDayArray);
    },
    getToken(){
      this.weekOfday = parseInt(moment().format('d')) ;//计算今天是这周第几天
      this.getWeek(this.weekOfday);
      // this.charWeek();
      this.$bridge.callHandler('getAccessToken',null,(res)=>{
      //   // alert('获取app响应数据:'+res)
        this.token = "Bearer " + res;
        this.$bridge.callHandler('getId',null,(id)=>{
           console.log(" id "+id); 
          //  alert(" id "+id);
           this.id = id;
           this.charDay();
        })
      })
    },
    weekBefor(){
       this.weekPage.currentPage--;
       this.getWeek(this.weekOfday+=7);
       this.doWeek();
    },
    weekNext(){
      this.weekPage.currentPage++;
      this.getWeek(this.weekOfday-=7);
      this.doWeek();
    },

    dayBefor(){
       this.dayPage.currentPage--;
       this.dayOfHour = moment(this.dayOfHour).subtract(1, 'days').format("YYYY-MM-DD")
       this.doDay();
    },
    dayNext(){
      this.dayPage.currentPage++;
      this.dayOfHour = moment(this.dayOfHour).subtract(-1, 'days').format("YYYY-MM-DD")
      this.doDay();
    },
    doWeek() {
      this.cur = 'week';
      // 基于准备好的dom，初始化echarts实例
      this.$nextTick(() => {
        // this.weekOfday = parseInt(moment().format('d')) ;//计算今天是这周第几天
        // this.getWeek(this.weekOfday);
        this.charWeek();
      });
    },
    doDay() {
      this.cur = 'day';
      this.$nextTick(() => {
        this.charDay();
      });
    },
    charDay() {
       // 指定图表的配置项和数据
      this.dayOption = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          icon: "circle",
          top: "5%",
          left: "5%",
          itemWidth: 8,
          itemGap: 20,
          textStyle: {
            color: "#556677",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            // saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: true, //坐标轴两边留白
          data: this.hours,
          axisLabel: {
            //坐标轴刻度标签的相关设置。
            show: true,
            interval: 1, //设置为 1，表示『隔一个标签显示一个标签』
            // margin:15,
            // width:100,
            textStyle: {
              color: "#666",
              fontStyle: "normal",
              fontFamily: "微软雅黑",
              fontSize: 12,
            },
            formatter: function(params) {
              var newParamsName = "";
              var paramsNameNumber = params.length;
              var provideNumber = 5; //一行显示几个字
              var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
              // console.log(params);
              if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  var start = p * provideNumber;
                  var end = start + provideNumber;
                  if (p == rowNumber - 1) {
                    tempStr = params.substring(start, paramsNameNumber);
                  } else {
                    tempStr = params.substring(start, end) + "\n";
                  }
                  newParamsName += tempStr;
                }
              } else {
                newParamsName = params;
              }
              return newParamsName;
            },
            //rotate:50,
          },
          
          axisTick: {
            //坐标轴刻度相关设置。
            // show: false,
            alignWithLabel: true
          },
          axisLine: {
            //坐标轴轴线相关设置
            interval:0,
            show: false,
            lineStyle: {
              color: "#E5E9ED",
              // opacity:0.2
            },
          },
          splitLine: {
            //坐标轴在 grid 区域中的分隔线。
            show: false,
            lineStyle: {
              color: "#E5E9ED",
              opacity: 0.1,
            },
          },
        },
        yAxis: [
          {
            type: "value",
            splitNumber: 5,
            axisLabel: {
              show: false,
              textStyle: {
                color: "#a8aab0",
                fontStyle: "normal",
                fontFamily: "微软雅黑",
                fontSize: 12,
              },
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#E5E9ED",
                type: "dashed",
                // 	opacity:0.1
              },
            },
          },
        ],
        series: [
          {
            name: "功率",
            type: "bar",
            symbol: "none",
         
            itemStyle: {
              normal: {
                color: "#12A9E6",
                lineStyle: {
                  color: "#12A9E6",
                  width: 1,
                },
                areaStyle: {
                  color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      offset: 0,
                      color: "rgba(255,255,255,0)",
                    },
                    {
                      offset: 1,
                      color: "rgba(118,231,248,1)",
                    },
                  ]),
                },
              },
            },
            data: [],
          },
        ],
      };
      this.dayChart = echarts.init(document.getElementById("dayEcharts"));
      // this.weekChart = this.$echarts.init(this.$refs.weekEcharts);
    
      this.autoHeight = document.documentElement.clientHeight || document.body.clientHeight;

      this.curHeight = this.autoHeight - 40; //减去页面上固定高度height

      this.dayChart.resize({ height: this.curHeight });

      //请求接口数据
      devicePowerHourLog(this.token,this.dayPage.currentPage,this.dayPage.pageSize,{ deviceId :this.id, startDate: this.dayOfHour }).then(res =>{
        console.log(" this.data ",res);
        const data = res.data.data.records;
        
         this.isEmpty = true;

        let labelArr =[];
        let powerArr =[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
        data.filter( (item) =>{
            powerArr[moment(item.createTime).format('HH')*1] = item.power;
        });
        console.log("labelArr == ",labelArr)
        console.log("powerArr == ",powerArr)
        console.log(" this.dayOption ",this.dayOption);
        
        // this.dayOption.xAxis.data = labelArr;
        this.dayOption.series[0].data = powerArr;
        // 使用刚指定的配置项和数据显示图表。
        this.dayChart.setOption(this.dayOption);
      })
      // 使用刚指定的配置项和数据显示图表。
      this.dayChart.setOption(this.dayOption);
      window.addEventListener("resize", function() {
        setTimeout(function() {
          this.dayChart.resize();
        }, 100);
      });
    },
    charWeek() {
      this.weekChart = echarts.init(document.getElementById("weekEcharts"));
      // this.weekChart = this.$echarts.init(this.$refs.weekEcharts);
      // 指定图表的配置项和数据
      this.weekOption = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          icon: "circle",
          top: "5%",
          left: "5%",
          itemWidth: 8,
          itemGap: 20,
          textStyle: {
            color: "#556677",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            // saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: true, //坐标轴两边留白
          data: this.weekDayArray,
          axisLabel: {
            //坐标轴刻度标签的相关设置。
            show: true,
            interval: 0, //设置为 1，表示『隔一个标签显示一个标签』
            // margin:15,
            // width:100,
            textStyle: {
              color: "#666",
              fontStyle: "normal",
              fontFamily: "微软雅黑",
              fontSize: 12,
            },
            formatter: function(params) {
              var newParamsName = "";
              var paramsNameNumber = params.length;
              var provideNumber = 5; //一行显示几个字
              var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
              // console.log(params);
              if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  var start = p * provideNumber;
                  var end = start + provideNumber;
                  if (p == rowNumber - 1) {
                    tempStr = params.substring(start, paramsNameNumber);
                  } else {
                    tempStr = params.substring(start, end) + "\n";
                  }
                  newParamsName += tempStr;
                }
              } else {
                newParamsName = params;
              }
              return newParamsName;
            },
            //rotate:50,
          },
          axisTick: {
            //坐标轴刻度相关设置。
            show: false,
          },
          axisLine: {
            //坐标轴轴线相关设置
            show: false,
            lineStyle: {
              color: "#E5E9ED",
              // opacity:0.2
            },
          },
          splitLine: {
            //坐标轴在 grid 区域中的分隔线。
            show: false,
            lineStyle: {
              color: "#E5E9ED",
              opacity: 0.1,
            },
          },
        },
        yAxis: [
          {
            type: "value",
            splitNumber: 5,
            axisLabel: {
              show: false,
              textStyle: {
                color: "#a8aab0",
                fontStyle: "normal",
                fontFamily: "微软雅黑",
                fontSize: 12,
              },
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#E5E9ED",
                type: "dashed",
                // 	opacity:0.1
              },
            },
          },
        ],
        series: [
          {
            name: "功率",
            type: "line",
            symbol: "none",
 
            itemStyle: {
              normal: {
                color: "#12A9E6",
                lineStyle: {
                  color: "#12A9E6",
                  width: 1,
                },
                areaStyle: {
                  color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      offset: 0,
                      color: "rgba(255,255,255,0)",
                    },
                    {
                      offset: 1,
                      color: "rgba(118,231,248,1)",
                    },
                  ]),
                },
              },
            },
            data: [],
          },
        ],
      };

      this.autoHeight = document.documentElement.clientHeight || document.body.clientHeight;
      this.curHeight = this.autoHeight - 40; //减去页面上固定高度height
      this.weekChart.resize({ height: this.curHeight });
      //请求接口数据
      devicePowerWeekLog(this.token,this.weekPage.currentPage,this.weekPage.pageSize,{
        deviceId:this.id,
        startDate:this.start,
        endDate:this.end
      }).then(res =>{
        const data = res.data.data.records;
        console.log(" this.data ",data);
        let labelArr =[];
        let powerArr =[0,0,0,0,0,0,0];
         this.isEmpty = true;
         data.filter( (item) =>{
            powerArr[parseInt(moment(item.createTime).format('d'))] = item.power;
            this.isEmpty = false;
         });
        console.log("labelArr == ",labelArr)
        console.log("powerArr == ",powerArr)
        console.log(" this.weekOption ",this.weekOption);

        // this.weekOption.xAxis.data = labelArr;
        this.weekOption.series[0].data = powerArr;
        // 使用刚指定的配置项和数据显示图表。
        this.weekChart.setOption(this.weekOption);
      })
      // 使用刚指定的配置项和数据显示图表。
      this.weekChart.setOption(this.weekOption);
      window.addEventListener("resize", function() {
        setTimeout(function() {
          this.weekChart.resize();
        }, 100);
      });
    },

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
html {
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: Arial, Microsoft YaHei, SimHei, STHeiti, SimSun, STSong,
    sans-serif;
  color: #333;
  background-color: #fff;
  line-height: 1;
  font-weight: normal;
}

body,
ul,
p,
h1,
h2,
h3,
h4 {
  margin: 0;
}

a {
  color: #333;
  text-decoration: none;
}

a,
input,
button,
select {
  outline: none;
}

ul {
  padding: 0;
  list-style-type: none;
  list-style-position: outside;
}

h1,
h2,
h3,
h4 {
  font-family: inherit;
  font-size: inherit;
  font-weight: normal;
  line-height: inherit;
  text-align: inherit;
}

section,
button {
  display: block;
}

img {
  border: none;
  max-width: 100%;
}

/**统一约束规范**/
.fl {
  float: left;
}

.fr {
  float: right;
}

html {
  font-size: 100px;
}

.header {
  margin-top: 10px;
  height: 40px;
}

.nav {
  height: 64px;
  width: 100%;
  text-align: center;
}

.nav a {
  display: inline-block;
  width: 40px;
  height: 20px;
  line-height: 20px;
  background-color: #f1f1f1;
  /* border-radius: 20px; */
  font-size: 14px;
  border-radius: 50px;
}

.nav a:first-child {
  margin-right: 20px;
}

.nav a.cur {
  background-color: #5a8bdb;
  color: #fff;
}
 
.content .item {
  height: 40px;
  /* overflow: hidden; */
}

.content .item ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.content .item li {
  width: 40%;
  font-size: 12px;
  color: #333;
  text-align: center;
}

.content .item .count {
  font-size: 16px;
}

.content .item .count span {
  font-size: 16px;
}

.content .item .info {
  color: #999;
  margin-top: 5px;
}

.charts_box {
  /* margin-top: 10px; */
  /* height: 10.15rem; */
  position: relative;
}
.tools {
  font-size: 16px;
  color: #999;
}
.left_arrow {
  width: 15px;
  position: absolute;
  bottom: 16px;
  left: 0px;
  padding: 0px 5px
}
.right_arrow {
  width: 15px;
  position: absolute;
  bottom: 16px;
  right: 0px;
  padding: 0px 5px
}
.charts_box .title {
  /* font-size: 0.24rem; */
  color: #666;
  /* padding: 0 0.5rem; */
}

.charts_box .title .dot {
  display: inline-block;
  width: 0.16rem;
  height: 0.16rem;
  border-radius: 50%;
  background-color: #12a9e6;
  margin-right: 0.08rem;
  vertical-align: middle;
}

.charts {
  width: 100%;
  height: 100%;
}

.display-block {
  display: block;
}

.none-box{
  position: absolute;
  width: calc(100% - 20px); 
  margin-top: 240px;
  z-index: 999;
}
.none-img{
 width: 40%;
}
</style>
