<template>
  <div class="upgrade-box">
    <div class="none-box" v-if="isEmpty">
       <img src="img/none.png" class="none-img" >
       <div class="text">暂无数据</div> 
    </div>
    <div class="">
      <div class="item-box" v-for="(item,index) in data" :key="index">
        <div class="device-img">
          <img :src="item.imgUrl" />
        </div>
        <div class="device-name">
          <span class="device-name">{{item.deviceName}}</span>
          <span v-if="item.upgradeFlag == 0 && !item.isOnline" class="upgrade-btn-off" >离线</span>
          <span v-if="item.upgradeFlag == 0 && item.isOnline" class="upgrade-btn color-green" @click="appConfirmBtn(item)">升级</span>
          <span v-if="item.upgradeFlag == 1" class="upgrade-btn color-green">正在升级</span>
          <span v-if="item.upgradeFlag == 2" class="upgrade-btn color-green">升级成功</span>
          <span v-if="item.upgradeFlag == 3" class="upgrade-btn color-red">升级失败</span>
        </div>
        <div class="version">当前固件版本：{{item.usedVersion}}</div>
        <div class="version">最新固件版本：{{item.firmwareVersion}}</div>
        <div :id="'showContent'+index" class="version-content" v-html="item.remark"></div>
        <div :id="'showMore'+index" class="version-more" @click="showMore(index)">...... 更多</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getList ,appConfirm } from "@/api/homeFirmwareUpgrade/home_firmware_upgrade";
export default {
  name: "log",
  data() {
    return {
      token:'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiLpu5jorqTlkI3np7AiLCJzY29wZSI6WyJhbGwiXSwiZXhwIjoyMDMzNzQ5MjY0LCJ1c2VySWQiOjEsImF1dGhvcml0aWVzIjpbIkFETUlOIl0sImp0aSI6Ijc4YmI1M2YwLTUwNGQtNGIxYS05ZTQxLTg0Njk0NGY3MzUzZCIsImNsaWVudF9pZCI6ImlvdF9tb2JpbGVfaW9zIiwidXNlcm5hbWUiOiLpu5jorqTlkI3np7AifQ.WU3aVjit4ASVEV3UOXMXfKvNfG01V_g1hjYyuaWssifYF_xsExUP7fWNXS_qTC4e7SsQtaZwSvybkYnTieXD0zg2iqEYuHHlS-MAmWiBeNTxJr5oHoXiduRaIRjJkD_AtbpVwak42Mi2C1i3vJUnoWykQ05483kOJtF-eHnRjF36LsocQH75h7jQ94narIM5-XjUSfw9lbopwU_8bWvD6YIm2qZ0oLaDp8mVrNCOfDFFbQTNon4AOJLJyhrCyYBCMd4SfS-VZ7uYdy2MskOU_Zr_HGHmCTp69UgADRCige9h9A7ZhQFpV5Yc04hM2ySxeMZpscwxmnW0nKMbQZx0Zw',
      id:'',
      data:[],
      isEmpty:true,
      params: {
        pageSize: 10,
        index: 1,
        homeId: 1,
        total: 0,
      },
    };
  },
  props: {
    msg: String,
  },
  created: function() {
  },
  mounted: function() {
     this.getToken();
  },
  methods: {
    getToken(){
      this.$bridge.callHandler('getAccessToken',null,(res)=>{
        // alert('获取app响应数据:'+res)
        this.token = "Bearer " + res;
        this.onLoad();
      })
    },
    receiveMsgFromParent: function(msg) {
      //webView 传递参数
      // alert("" + msg);
      this.childMsg = "receive msg = " + msg;
    },
    goDetail(item) {
      // console.log(" router ",this.$router);
      this.$router.push({
        path: "/serviceInfo?id=" + item.id,
      });
    },
    onLoad() {
      this.loading = true;
      getList(
        this.token,this.params
      ).then(res => {
        const data = [];
        this.data = res.data.data.records;
        //alert('请求数据:'+data)
        console.log(" data ", data);
        if(this.data.length >0){
          this.isEmpty =false;
        }
        this.loading = false;
      });
    },
    appConfirmBtn(item){
      // const confirmResult = this.$confirm('确认要升级固件版本吗?', null, {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).catch(err => err)
      // /*如果用户确认打印confirm,如果用户取消显示cancel*/
      // if (confirmResult === 'confirm') {
        
      // } 
    
      // console.log('确认了删除')
        appConfirm(this.token,item).then((res) => {
          this.onLoad();
          console.log(" res " +res);
        });
        
    },
    showMore(index){
        let divName = "showContent"+index;
        let divMore = "showMore"+index;
        if(document.getElementById(divMore).innerText == "收起"){
          document.getElementById(divMore).innerText = "...... 更多";
          document.getElementById(divName).style.height = "46px";
          document.getElementById(divName).style.overflow = "hidden";
        }else{
          document.getElementById(divMore).innerText = "收起";
          document.getElementById(divName).style.height = "auto";
          document.getElementById(divName).style.overflow = "auto";
        }
    }
  },
};
</script>

<style scoped>
.item-box {
  /* margin: 10px 0; */
  padding: 10px 0 10px 10px;
  text-align: left;
   border-bottom: 1px solid  #EFEFEF;
}
.upgrade-box{
}
.device-name {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 800;
  color: #333333;
  line-height: 23px;
}
.room-name {
  margin-left: 10px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 19px;
  border: 1px solid #5a8bdb;
  border-radius: 50px;
  padding: 0 10px;
}
.upgrade-btn {
  float: right;
  height:22px;
  border-radius: 17px;
  padding: 3px 15px 2px 15px;
  font-size: 14px;
  line-height:22px;
  margin-right:20px;
}
.color-green {
  color: #27D92B;
  border: 1px solid  #27D92B;
}
.color-red {
  color: #f30404;
  border: 1px solid  #f30404;
}
.upgrade-btn-off{
  float: right;
  height:22px;
  color: #4B4B4B;
  border-radius: 17px;
  padding: 3px 15px 2px 15px;
  font-size: 14px;
  line-height:22px;
  background: #EFEFEF;
  margin-right:20px;
}
.version {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 15px;
  margin: 10px;
  margin-left:45px;
}
.version-content {
  margin-left: 10px;
  font-size: 14px;
  height:46px;
  overflow:hidden;
  font-family: PingFangSC-Regular, PingFang SC;
  white-space: pre-line;
  font-weight: 400;
  color: #333333;
  margin-left:45px;
}
.version-content p{
  line-height:30px;
}
.version-more{
  text-align:right;
  color:#4B4B4B;
  margin-right:20px;
}
.device-img {
    display:inline-block;
    float:left;
}
.device-img img{
    width:40px;
    height:40px;
}
.device-name{
    height:40px;
    line-height:40px;
    margin-left:5px;
}
.device-conent{
    display:inline-block;
    float:left;
}
.room-class{
    text-align:left;
    height:40px;
    line-height:42px;
    background: #F8F8F8;
    padding-left:12px;
    font-size:18px;
    font-color:#1D1C28;
}
.none-box{
  position: absolute;
  width: calc(100% - 20px);
  margin-top: 240px;
  z-index: 999;
}
.none-img{
 width: 40%;
}
</style>
