<template>
  <div class="log-box">
      <div class="help-img-box" :style="'width:'+imgboxwidth+'px'">
        <img src="img/helplog.png" />
        <div>APP使用手册攻略</div>
      </div>
      <div class="help-title">
        <div class="spot"></div>
        <div class="title">常见问题</div>
      </div>
    <el-collapse accordion v-model="activeNames">
      <el-collapse-item
        class="log-collapse "
        :name="index"
        v-for="(item, index) in data"
        :key="index"
      >
        <template slot="title">
          <span class="day">{{ item.title }}</span>
        </template>
        <div class="log-body" v-html="getDetail(item)"></div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import {getList} from "@/api/helpInfo/help_info";

export default {
  name: "help",
  data() {
    return {
      token: "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiLpu5jorqTlkI3np7AiLCJzY29wZSI6WyJhbGwiXSwiZXhwIjoyMDMzNzQ5MjY0LCJ1c2VySWQiOjEsImF1dGhvcml0aWVzIjpbIkFETUlOIl0sImp0aSI6Ijc4YmI1M2YwLTUwNGQtNGIxYS05ZTQxLTg0Njk0NGY3MzUzZCIsImNsaWVudF9pZCI6ImlvdF9tb2JpbGVfaW9zIiwidXNlcm5hbWUiOiLpu5jorqTlkI3np7AifQ.WU3aVjit4ASVEV3UOXMXfKvNfG01V_g1hjYyuaWssifYF_xsExUP7fWNXS_qTC4e7SsQtaZwSvybkYnTieXD0zg2iqEYuHHlS-MAmWiBeNTxJr5oHoXiduRaIRjJkD_AtbpVwak42Mi2C1i3vJUnoWykQ05483kOJtF-eHnRjF36LsocQH75h7jQ94narIM5-XjUSfw9lbopwU_8bWvD6YIm2qZ0oLaDp8mVrNCOfDFFbQTNon4AOJLJyhrCyYBCMd4SfS-VZ7uYdy2MskOU_Zr_HGHmCTp69UgADRCige9h9A7ZhQFpV5Yc04hM2ySxeMZpscwxmnW0nKMbQZx0Zw",
      data: [],
      form: {},
      query: {},
      loading: true,
      isiOS: !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
      imgboxwidth: 320,
      params: {
        index: 1,
        pageSize: 10,
        total:0,
        helpClass: 3,
        helpSubClass: 1
      },
      activeNames: ["1"],
    };
  },
  props: {
    msg: String,
  },
  created: function() {
    this.getToken();
    // this.onLoad(this.page);
  },
  mounted: function() {
    if(this.isiOS){
      this.imgboxwidth = 370;
    }
     this.getToken();
     console.log(" this.token "+this.token);
    //  this.onLoad(this.page);
  },
  methods: {
    getToken(){
      this.$bridge.callHandler('getAccessToken',null,(res)=>{
      //   // alert('获取app响应数据:'+res)
        this.token = "Bearer " + res;
        this.onLoad();
      })
    },
    receiveMsgFromParent: function(msg) {
      //webView 传递参数
      //alert("" + msg);
      this.childMsg = "receive msg = " + msg;
    },
    getDetail(item) {
      /**
       * return decodeURI(item.content);
       * */
      return item.content;
    },
    onLoad() {
      this.loading = true;
      getList(
          this.token,
          this.params
      ).then(res => {
        const data = res.data.data;
        console.log(" data ", data);
        this.params.total = data.total;
        this.data = data.records;
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>
.head-box {
}
.help-img-box {
  height: 102px;
  margin:5px 30px 0 15px;
  background: #FFFFFF;
  box-shadow: 0px 2px 76px 0px rgba(121, 121, 121, 0.1);
}
.help-img-box img {
    float:left;
    width: 60px;
    height: 60px;
    margin:20px 0 21px 22px;

}
.help-img-box div {
    display:inline-block;
    float:left;
    width:130px;
    height: 15px;
    margin:44px 90px 45px 16px;
    font-size:15px;

}
.help-title{
    width:100%;
    height:49px;
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #666666;
    font-weight: 400;
    display: flex;
    padding-left:20px;
}
.help-title .title{
    line-height:49px;
    display:inline-block;
    float:left;
}
.help-title .spot{
    display:inline-block;
    float:left;
    width: 6px;
    height: 6px;
    background: #363636;
    border-radius: 50%;
    margin:22px 3px 0 0;
    line-height:49px;
    align-self:flex-start;
}
.log-box {
  text-align: left;
}
.log-box .day {
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 21px;
  padding-left:20px;
}
.log-box .month {
  margin-left: 10px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 19px;
}
.log-box .week {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 19px;
  right: 40px;
  position: absolute;
}
.log-body {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 19px;
  padding-left:20px;
}
.log-time {
  border-top: 1px solid #ebeef5;
  height: 40px;
  line-height: 40px;
}
.el-collapse-item__content {
  padding-bottom: 0px !important;
}
.el-collapse-item__arrow {
  color: #999999 !important;
}
</style>
