<template>
  <div class="serviceList">

    <div class="none-box" v-if='data.length == 0'>
       <img src="img/none.png" class="none-img" >
       <div class="text">暂无数据</div> 
    </div>
         <!-- {{token}} -->
      <!-- <button class="btn" @click="getToken">获取APPToken</button> -->
    <!-- <router-link :to="{path:'/serviceInfo/'+item.id}" v-for="(item,index) in data" :key="index"> -->
    <div
      class="item-box"
      @click="goDetail(item)"
      v-for="(item, index) in data"
      :key="index"
    >
      <div class="img-box">
        <img :src="item.imgUrl" />
      </div>
      <div class="name">{{ item.title }}</div>
    </div>
    <!-- </router-link> -->

    {{ this.childMsg }}
  </div>
</template>
<script>
import { getList } from "@/api/helpInfo/help_info";
// import { getStore,setStore} from '@/util/store';
// import { getToken,setToken} from '@/util/auth';
export default {
  name: "serviceList",
  data() {
    return {
      token:"",
      data: [],
      form: {},
      query: {},
      loading: true,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      childMsg: "",
    };
  },
  props: {
    msg: String,
  },
  created: function() {
    // this.getToken();
    // this.onLoad(this.page);
  },
  mounted: function() {
     this.getToken();
    //  console.log(" this.token "+this.token);
    //  this.onLoad(this.page);
  },
  methods: {
    getToken(){
      this.$bridge.callHandler('getAccessToken',null,(res)=>{
        // alert('获取app响应数据:'+res)
        this.token = "Bearer " + res;
        this.onLoad(this.page);
      })
    },
    receiveMsgFromParent: function(msg) {
      //webView 传递参数
      alert("" + msg);
      this.childMsg = "receive msg = " + msg;
    },
    goDetail(item) {
      // console.log(" router ",this.$router);
      this.$router.push({
        path: "/serviceInfo?id=" + item.id,
      });
    },
    onLoad(page, params = {}) {
      this.query.helpClass =  "服务信息" ;
      this.loading = true;
      getList(
        this.token,
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        // alert('请求数据:'+data)
        console.log(" data ", data);
        this.page.total = data.total;
        this.data = data.records;
        this.loading = false;
      });
    },
  },
};
</script>
<style scoped>
.item-box {
  float: left;
  width: 33%;
  text-align: center;
}
/* .img-box */
.img-box img {
  width: 70px;
  height: 70px;
}
.serviceList .name {
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
  text-align: center;
}

.none-box{
  position: absolute;
  width: calc(100% - 20px); 
  margin-top: 240px;
  z-index: 999;
}
.none-img{
 width: 40%;
}
</style>
