<template>
  <div class="hello">
        {{token}}
    {{id}}
    <header class="header">
      <nav class="nav">
        <a v-on:click.stop="doWeek" :class="{ cur: cur == 'week' }">周</a>
        <a v-on:click.stop="doYear" :class="{ cur: cur == 'year' }">年</a>
      </nav>
    </header>
    <section class="content" v-if="cur == 'week'">
      <div class="item">
        <ul>
          <li>
            <div class="count"><span>100</span>次</div>
            <p class="info">当周点击数</p>
          </li>
          <li>
            <div class="count"><span>-</span>次</div>
            <p class="info">上周点击数</p>
          </li>
        </ul>
      </div>
      <div class="charts_box">
        <!-- <div class="title"><i class="dot"></i>最新用电量</div> -->
        <div
          class="charts"
          id="weekEcharts"
          :style="{ height: curHeight }"
        ></div>
        <div class="tools">
          <div class="left_arrow">
            <img src="img/left.png" />
          </div>
          <div class="right_arrow">
            <img src="img/right.png" />
          </div>
        </div>
      </div>
    </section>
    <section class="content" v-if="cur == 'year'">
      <div class="item">
        <ul>
          <li>
            <div class="count"><span>52</span>次</div>
            <p class="info">当年点击数</p>
          </li>
          <li>
            <div class="count"><span>-</span>次</div>
            <p class="info">上年点击数</p>
          </li>
        </ul>
      </div>
      <div class="charts_box">
        <!-- <div class="title"><i class="dot"></i>最新用电量</div> -->
        <div
          class="charts"
          id="yearEcharts"
          :style="{ height: curHeight }"
        ></div>
        <div class="tools">
          <div class="left_arrow">
            <img src="img/left.png" />
          </div>
          <div class="right_arrow">
            <img src="img/right.png" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import moment from 'moment'
// import { getList as deviceActionDayLog } from "@/api/deviceActionDayLog/device_action_day_log/";
// import { getList as deviceActionHourLog } from "@//api/deviceActionHourLog/device_action_hour_log/";
// 引入 ECharts 主模块
let echarts = require("echarts/lib/echarts");
// 引入折线图/柱状图等组件
require("echarts/lib/chart/line");
require("echarts/lib/chart/bar");
require("echarts/lib/chart/radar");
// 引入提示框和title组件，图例
require("echarts/lib/component/toolbox");
require("echarts/lib/component/grid");
require("echarts/lib/component/tooltip");
require("echarts/lib/component/title");
require("echarts/lib/component/legend");
require("echarts/lib/component/legendScroll"); //图例滚动

export default {
  name: "Kwh",

  data() {
    return {
      cur: "week",
      curHeight: 100,
      weekChart: null,
      weelOption: {},
      yearChart: null,
      yearOption: {},
    };
  },
  props: {
    msg: String,
  },
  created: function() {
    setTimeout(() => {}, 200);
  },
  mounted: function() {
    // this.weekChart = this.echarts.init(document.getElementById("weekEcharts"));
    // this.charWeek();
    this.getToken();
  },
  // //数据自动刷新，必然需要一个监听机制告诉Echarts重新设置数据
  // watch: {
  //   //观察option的变化
  //   option: {
  //     handler(newVal, oldVal) {
  //       if (this.myChart) {
  //         if (newVal) {
  //           this.myChart.setOption(newVal);
  //         } else {
  //           this.myChart.setOption(oldVal);
  //         }
  //       } else {
  //         this.charWeek();
  //       }
  //     },
  //     deep: true, //对象内部属性的监听，关键。
  //   },
  // },

  methods: {
     getToken(){
      this.weekOfday = parseInt(moment().format('d')) ;//计算今天是这周第几天
      this.getWeek(this.weekOfday);
      // this.charWeek();
      this.$bridge.callHandler('getAccessToken',null,(res)=>{
      //   // alert('获取app响应数据:'+res)
        this.token = "Bearer " + res;
        this.$bridge.callHandler('getId',null,(id)=>{
           console.log(" id "+id); 
          //  alert(" id "+id);
           this.id = id;
           this.charDay();
        })
      })
    },
    doWeek() {
      this.cur = "week";
      // 基于准备好的dom，初始化echarts实例
      this.$nextTick(() => {
        this.charWeek();
      });
    },
    doYear() {
      this.cur = "year";
      this.$nextTick(() => {
        this.charYear();
      });
    },
    charWeek() {
      this.weekChart = echarts.init(document.getElementById("weekEcharts"));
      // this.weekChart = this.$echarts.init(this.$refs.weekEcharts);
      // 指定图表的配置项和数据
      this.weekOption = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          icon: "circle",
          top: "5%",
          left: "5%",
          itemWidth: 8,
          itemGap: 20,
          textStyle: {
            color: "#556677",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            // saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: true, //坐标轴两边留白
          data: [
            "12/23周二",
            "12/24",
            "12/25",
            "12/26",
            "12/27",
            "12/28",
            "12/29周一",
          ],
          axisLabel: {
            //坐标轴刻度标签的相关设置。
            show: true,
            interval: 0, //设置为 1，表示『隔一个标签显示一个标签』
            // margin:15,
            // width:100,
            textStyle: {
              color: "#666",
              fontStyle: "normal",
              fontFamily: "微软雅黑",
              fontSize: 12,
            },
            formatter: function(params) {
              var newParamsName = "";
              var paramsNameNumber = params.length;
              var provideNumber = 5; //一行显示几个字
              var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
              console.log(params);
              if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  var start = p * provideNumber;
                  var end = start + provideNumber;
                  if (p == rowNumber - 1) {
                    tempStr = params.substring(start, paramsNameNumber);
                  } else {
                    tempStr = params.substring(start, end) + "\n";
                  }
                  newParamsName += tempStr;
                }
              } else {
                newParamsName = params;
              }
              return newParamsName;
            },
            //rotate:50,
          },
          axisTick: {
            //坐标轴刻度相关设置。
            show: false,
          },
          axisLine: {
            //坐标轴轴线相关设置
            show: false,
            lineStyle: {
              color: "#E5E9ED",
              // opacity:0.2
            },
          },
          splitLine: {
            //坐标轴在 grid 区域中的分隔线。
            show: false,
            lineStyle: {
              color: "#E5E9ED",
              opacity: 0.1,
            },
          },
        },
        yAxis: [
          {
            type: "value",
            splitNumber: 5,
            axisLabel: {
              show: false,
              textStyle: {
                color: "#a8aab0",
                fontStyle: "normal",
                fontFamily: "微软雅黑",
                fontSize: 12,
              },
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#E5E9ED",
                type: "dashed",
                // 	opacity:0.1
              },
            },
          },
        ],
        series: [
          {
            name: "单击",
            type: "line",
            symbol: "none",
            itemStyle: {
              normal: {
                color: "#5A8BDB",
                lineStyle: {
                  color: "#5A8BDB",
                  width: 1,
                },
              },
            },
            data: [0, 0, 1, 0, 0, 0, 0],
          },
          {
            name: "双击",
            type: "line",
            symbol: "none",
            itemStyle: {
              normal: {
                color: "#12A9E6",
                lineStyle: {
                  color: "#12A9E6",
                  width: 1,
                },
              },
            },
            data: [0, 0, 1, 0, 0, 0, 0],
          },
          {
            name: "长按",
            type: "line",
            symbol: "none",
            itemStyle: {
              normal: {
                color: "#42C7FE",
                lineStyle: {
                  color: "#42C7FE",
                  width: 1,
                },
              },
            },
            data: [0, 0, 1, 0, 0, 0, 0],
          },
        ],
      };

      this.autoHeight =
        document.documentElement.clientHeight || document.body.clientHeight;

      this.curHeight = this.autoHeight - 160; //减去页面上固定高度height

      this.weekChart.resize({ height: this.curHeight });

      // 使用刚指定的配置项和数据显示图表。
      this.weekChart.setOption(this.weekOption);
      window.addEventListener("resize", function() {
        setTimeout(function() {
          this.weekChart.resize();
        }, 100);
      });
    },
    charYear() {
      this.yearChart = echarts.init(document.getElementById("yearEcharts"));
      // this.weekChart = this.$echarts.init(this.$refs.weekEcharts);
      // 指定图表的配置项和数据
      this.yearOption = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          icon: "circle",
          top: "5%",
          left: "5%",
          itemWidth: 8,
          itemGap: 20,
          textStyle: {
            color: "#556677",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            // saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: true, //坐标轴两边留白
          data: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
          axisLabel: {
            //坐标轴刻度标签的相关设置。
            show: true,
            interval: 0, //设置为 1，表示『隔一个标签显示一个标签』
            // margin:15,
            // width:100,
            textStyle: {
              color: "#666",
              fontStyle: "normal",
              fontFamily: "微软雅黑",
              fontSize: 12,
            },
            formatter: function(params) {
              var newParamsName = "";
              var paramsNameNumber = params.length;
              var provideNumber = 5; //一行显示几个字
              var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
              // console.log(params);
              if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  var start = p * provideNumber;
                  var end = start + provideNumber;
                  if (p == rowNumber - 1) {
                    tempStr = params.substring(start, paramsNameNumber);
                  } else {
                    tempStr = params.substring(start, end) + "\n";
                  }
                  newParamsName += tempStr;
                }
              } else {
                newParamsName = params;
              }
              return newParamsName;
            },
            //rotate:50,
          },
          axisTick: {
            //坐标轴刻度相关设置。
            show: false,
          },
          axisLine: {
            //坐标轴轴线相关设置
            show: false,
            lineStyle: {
              color: "#E5E9ED",
              // opacity:0.2
            },
          },
          splitLine: {
            //坐标轴在 grid 区域中的分隔线。
            show: false,
            lineStyle: {
              color: "#E5E9ED",
              opacity: 0.1,
            },
          },
        },
        yAxis: [
          {
            type: "value",
            splitNumber: 5,
            axisLabel: {
              show: false,
              textStyle: {
                color: "#a8aab0",
                fontStyle: "normal",
                fontFamily: "微软雅黑",
                fontSize: 12,
              },
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#E5E9ED",
                type: "dashed",
                // 	opacity:0.1
              },
            },
          },
        ],
        series: [
          {
            name: "单击",
            type: "line",
            symbol: "none",
            smooth: false,
            itemStyle: {
              normal: {
                color: "#5A8BDB",
                lineStyle: {
                  color: "#5A8BDB",
                  width: 1,
                },
              },
            },
            data: [
              0,
              0,
              1,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
            ],
          },
          {
            name: "双击",
            type: "line",
            symbol: "none",
            smooth: false,
            itemStyle: {
              normal: {
                color: "#12A9E6",
                lineStyle: {
                  color: "#12A9E6",
                  width: 1,
                },
              },
            },
            data: [
              0,
              0,
              1,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
            ],
          },
          {
            name: "长按",
            type: "line",
            symbol: "none",
            smooth: false,
            itemStyle: {
              normal: {
                color: "#42C7FE",
                lineStyle: {
                  color: "#42C7FE",
                  width: 1,
                },
              },
            },
            data: [
              0,
              0,
              1,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
            ],
          },
        ],
      };

      this.autoHeight =
        document.documentElement.clientHeight || document.body.clientHeight;

      this.curHeight = this.autoHeight - 160; //减去页面上固定高度height

      this.yearChart.resize({ height: this.curHeight });

      // 使用刚指定的配置项和数据显示图表。
      this.yearChart.setOption(this.yearOption);
      window.addEventListener("resize", function() {
        setTimeout(function() {
          this.yearChart.resize();
        }, 100);
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
html {
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: Arial, Microsoft YaHei, SimHei, STHeiti, SimSun, STSong,
    sans-serif;
  color: #333;
  background-color: #fff;
  line-height: 1;
  font-weight: normal;
}

body,
ul,
p,
h1,
h2,
h3,
h4 {
  margin: 0;
}

a {
  color: #333;
  text-decoration: none;
}

a,
input,
button,
select {
  outline: none;
}

ul {
  padding: 0;
  list-style-type: none;
  list-style-position: outside;
}

h1,
h2,
h3,
h4 {
  font-family: inherit;
  font-size: inherit;
  font-weight: normal;
  line-height: inherit;
  text-align: inherit;
}

section,
button {
  display: block;
}

img {
  border: none;
  max-width: 100%;
}

/**统一约束规范**/
.fl {
  float: left;
}

.fr {
  float: right;
}

html {
  font-size: 100px;
}

.header {
  height: 40px;
}

.nav {
  height: 64px;
  width: 100%;
  text-align: center;
}

.nav a {
  display: inline-block;
  width: 40px;
  height: 20px;
  line-height: 20px;
  background-color: #f1f1f1;
  /* border-radius: 20px; */
  font-size: 14px;
  border-radius: 50px;
}

.nav a:first-child {
  margin-right: 20px;
}

.nav a.cur {
  background-color: #5a8bdb;
  color: #fff;
}

.content .item {
  height: 50px;
  /* overflow: hidden; */
}

.content .item ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.content .item li {
  width: 40%;
  font-size: 12px;
  color: #333;
  text-align: center;
}

.content .item .count {
  font-size: 16px;
}

.content .item .count span {
  font-size: 16px;
}

.content .item .info {
  color: #999;
  margin-top: 5px;
}

.charts_box {
  margin-top: 10px;
  /* height: 10.15rem; */
  position: relative;
}
.tools {
  font-size: 16px;
  color: #999;
}
.left_arrow {
  width: 15px;
  position: absolute;
  bottom: 16px;
  left: 0px;
}
.right_arrow {
  width: 15px;
  position: absolute;
  bottom: 16px;
  right: 0px;
}
.charts_box .title {
  font-size: 0.24rem;
  color: #666;
  padding: 0 0.5rem;
}

.charts_box .title .dot {
  display: inline-block;
  width: 0.16rem;
  height: 0.16rem;
  border-radius: 50%;
  background-color: #12a9e6;
  margin-right: 0.08rem;
  vertical-align: middle;
}

.charts {
  width: 100%;
  height: 100%;
}

.display-block {
  display: block;
}
</style>
