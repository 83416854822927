<template>
<div id="app">
    <div class="agr-box ">
        <div class="Section0" style="layout-grid:15.6000pt;">
            <p class=MsoNormal align=center style="text-align:center;"><b><span style="mso-spacerun:'yes';font-family:黑体;font-weight:bold;font-size:24.0000pt;mso-font-kerning:1.0000pt;">
                        <font face="黑体">全宅卡威隐私政策</font>
                    </span></b><b><span style="mso-spacerun:'yes';font-family:黑体;font-weight:bold;font-size:24.0000pt;mso-font-kerning:1.0000pt;">
                        
                    </span></b></p>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">我们的隐私政策已于</font>
                    2023年08月31日更新，将会于2023年09月01日生效。我们对隐私政策进行了详细修订，从该日期开始，这一隐私政策能够提供有关我们如何管理您在使用所有全宅卡威产品和服务时透露的个人信息的隐私详情，特定的全宅卡威产品或服务提供独立的隐私政策除外。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">请花一些时间熟悉我们的隐私权惯例，如果您有任何问题，请告诉我们。</font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <h2><b><span style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        <font face="黑体">一、我们向您承诺</font>
                    </span></b><b><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:黑体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        
                    </span></b></h2>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">本隐私政策规定了北京欣智恒科技股份有限公司（下文简称</font>
                    "全宅卡威"或"我们")如何收集、使用、披露、处理和保护您在使用我们的产品和服务时通过【全宅卡威】APP提供给我们的信息。若我们要求您提供某些信息，以便在使用全宅卡威产品和服务时验证您的身份，我们将严格遵守本隐私政策和/或我们的用户条款与条件来使用这些信息。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">本隐私政策在制定时充分考虑到您的需求；您全面了解我们的个人资料收集和使用惯例，而且确信自己最终能控制提供给全宅卡威的所有个人信息，这一点至关重要。</font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">在这项隐私政策中，</font>
                    &#8220;个人信息&#8221;指所有通过有关特定个人的信息，或者与全宅卡威能够访问的其他关于该人的信息相结合后，能够直接或间接识别该人的所有数据。此类个人信息包括但不限于您提供或上传的信息和设备信息通过使用全宅卡威产品和服务或其他符合使用法律的操作，即表示您已阅读并接受本隐私政策中所述之所有条款，包括我们定期作出的任何变更。为遵守使用法律，包括本地数据保护法律（如欧盟的《全面数据保护法规》）,我们将会对于特定类别的个人数据的处理（如自动个体决策）征求您的明确同意。另外，我们承诺遵照适用法律（包括您所在地的数据保护法律）来保护您的个人信息的隐私、机密和安全。同时我们承诺确保我们的全体员工和代理商履行这些义务。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">最后，我们所希望的就是为我们的用户带来最好的体验。如果您对这一隐私政策中概括的数据处理惯例有任何疑问，请通过</font>
                    wanghongwei@iet.com.cn联系我们，以便我们处理您的需求，我们很高兴直接为您答疑解惑。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">如果您对我们的隐私政策或做法有任何疑问或疑虑，请通过</font>wanghongwei@iet.com.cn与我们联系。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <h2 style="margin-left:18.0000pt;text-indent:-18.0000pt;mso-list:l1 level1 lfo1;">
                <span style="font-family:Arial;mso-fareast-font-family:黑体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>1.<span>&nbsp;</span></span></span>
                <b><span style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        <font face="黑体">哪些信息会被收集以及我们如何使用这些信息？</font>
                    </span></b><b><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:黑体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        
                    </span></b>
            </h2>
            <h3><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">1</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">.</span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">1</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        <font face="宋体">收集的信息类别</font>
                    </span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        
                    </span></b></h3>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">为了向您提供我们的服务，我们会要求您提供向您提供服务所必需的个人信息。如果您不提供个人信息，我们可能无法向您提供我们的产品或服务。</font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">我们只会为了详细说明过的、明确的、合法的目的收集必要的信息，并保证不会以和上述目的不符的方式处理这些信息。我们可能会收集以下各类信息（无论其是否为个人信息）</font>:
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l10 level1 lfo2;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>1)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">您提供给我们或上传的信息（包括您的联系信息）</font>
                    :我们可能收集您提供给我们的个人信息，例如您的姓名、手机号、电子邮箱地址、全宅卡威账号信息（例如您的安全相关信息、姓名、生日、性别）、您可能接入到全宅卡威平台的设备同步的资料或数据，及在全宅卡威平台创建的账户和相关设置信息、您添加的设备或发送的消息、反馈等。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">手持终端或</font>
                    SIM卡相关信息：我们可能收集运行全宅卡威的手持终端相关的信息，例如IMEI编号、IMSI编号、系统版本、设备制造商信息、型号名称和网络运营商。我们还可能会收集与您账号绑定的相关设备信息，例如MAC地址、DID、设备网络状态（IP/网络信号）、固件版本。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l10 level1 lfo2;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>2)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">我们指定与您相关的信息：我们可能收集并使用诸如您的全宅卡威账号等信息。</font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l10 level1 lfo2;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>3)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">位置信息（仅适用于特定服务</font>
                    /功能）:与您的位置相关的各类信息，例如区域和国家代码、城市代码、移动网络代码、移动设备国家代码、小区识别码、地区名称、经纬度信息、时区设置和语言设置。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">登录信息：与您使用某些功能、移动应用程序和网站相关的信息，例如</font>
                    cookie和其他匿名标识符技术、IP地址、网络请求信息、临时消息历史、标准系统日志和系统崩溃信息等。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l10 level1 lfo2;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>4)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">其他信息：环境特征值（</font>ECV)(由全宅卡威账号、手机ID、连接的Wi-Fi账号及位置信息产生的数值）
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l10 level1 lfo2;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>5)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">账户凭证：有关您账户凭证的信息，例如密码、密码安全问题和回答等。</font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l10 level1 lfo2;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>6)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">设备信息：获取您设备ID, MAC地址等信息，主要为了给智能设备配网过程中，连接网络后读取设备id、mac地址，判断是否连接成功，同时管理和统计用户群体，分析用户的设备机型等。</font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l10 level1 lfo2;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>7)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">软件安装列表：获取您设备的软件安装列表，主要为了统计和推送一些告警信息时使用。</font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">
                        我们还可能收集其他与个人没有直接或间接关联的各类汇总的、匿名化的或者无标识的匿名信息。例如，在使用特定服务时，可能收集用户全宅卡威移动电话设备的设备型号和系统版本号。收集这类信息是为了提升我们提供给您的服务质量。
                    </font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <h3><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">1</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">.</span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">2</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        <font face="宋体">基于权限授权为您提供的其他附加服务</font>
                    </span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        
                    </span></b></h3>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">
                        为了向您提供更加优质、高效服务体验，我们将在向您提供的以下服务中需要您授权开启相应的终端设备权限，授权后我们会收集相应的信息。如果您拒绝开启该种权限，一般不会影响您使用全宅卡威
                    </font>APP的设备连接、管理等基本服务，如果您拒绝开启相应权限，可能会导致您无法使用相关功能。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l0 level1 lfo3;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>1)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">APP自启动关联启动的规则：极光推送核心SDK具有自启动和关联启动功能,</font>
                    为确保本应用处于关闭或后台运行状态下可正常接收到客户端推送的信息，将存在一定频率通过系纺发送广播唤醒本应用自启动或关联启动行为,是因实现功能及服务所必要的。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l0 level1 lfo3;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>2)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">基于语音信息的智能设备服务：当您选择使用语音功能控制设备开光状态时，我们将会请求您开启</font>
                    &#8220;麦克风&#8221;权限，以便设备写入音频文件并实现相关语音控制功能。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l0 level1 lfo3;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>3)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">基于相机权限的信息反馈服务：当您选择通过拍照的方式向我们反馈意见或建议，或通过拍照的方式上传头像时，我们会请求您开启</font>
                    &#8220;相机&#8221;或&#8220;摄像头&#8221;权限，以便于您拍摄并向我们提供相关信息。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l0 level1 lfo3;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>4)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">基于储存权限的文件上传和储存服务：当您使用图片上传功能时（例如：上传头像、上传反馈的图片、保存拍摄的照片时），我们会请求您开启</font>
                    &#8220;相册&#8221;或&#8220;储存&#8221;权限，以向您提供该种功能。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l0 level1 lfo3;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>4)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">基于通讯录的联系人添加功能：当您使用家庭成员邀请功能，共享家庭、共享设备时，我们会请求您开启访问</font>
                    &#8220;通讯录&#8221;权限，以便于获取联系人手机号码，并为您提供更便捷、顺畅的功能。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l0 level1 lfo3;">
                <span style="font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';color:rgb(25,25,25);font-size:10.5000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span style='mso-list:Ignore;'>6)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';color:rgb(25,25,25);font-size:10.5000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
                    <font face="微软雅黑">除上述内容外，应用中还集成了以下</font>SDK：
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <ul>
                <li>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">SDK名称:cn.jiguang.sdk:jcore:2.7.6&nbsp;(极光推送核心SDK);</span>
                    </p>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">使用目的：用于应用中当有告警消息时，接收推送告警内容;</span>
                    </p>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">使用方式：消息推送;</span>
                    </p>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">收集个人数据类型：自启动和关联启动;</span>
                    </p>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">BSSID:关于极光获取BSSID,请参见第三方SDK隐私政策;</span>
                    </p>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">第三方SDK隐私政策：https://www.jiguang.cn/license/privacy</span>
                    </p>
                </li>
                <li>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">SDK名称:cn.jiguang.sdk:jpush:4.0.6&nbsp;(极光推送SDK);</span>
                    </p>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">使用目的：用于应用中当有告警消息时，接收推送告警内容;</span>
                    </p>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">使用方式：消息推送;</span>
                    </p>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">收集个人数据类型：自启动和关联启动;</span>
                    </p>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">BSSID:关于极光获取BSSID,请参见第三方SDK隐私政策;</span>
                    </p>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">第三方SDK隐私政策：https://www.jiguang.cn/license/privacy;</span>
                    </p>
                </li>
                <li>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">SDK名称:com.amap.api:location:5.3.1&nbsp; (高德地图Android定位SDK);</span>
                    </p>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">使用目的:用于获取用户当前省市;天气查询API,用于为用户展示当前室外温度、湿度信息;</span>
                    </p>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">使用方式:定位;</span>
                    </p>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">收集个人数据类型:位置信息;</span>
                    </p>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">第三方SDK隐私政策:https://lbs.amap.com/pages/privacy/;</span>
                    </p>
                </li>
                <li>
                    <p class=MsoNormal style="text-indent:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">SDK名称:com.tencent.tauth:qqopensdk:3.53.0&nbsp;(腾讯QQ快速授权登录SDK);</span>
                    </p>
                    <p class=MsoNormal style="text-indent:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">使用目的:用于用户在登录时,可以通过QQ方式快速登录应用;</span>
                    </p>
                    <p class=MsoNormal style="text-indent:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">使用方式:QQ登录;</span>
                    </p>
                    <p class=MsoNormal style="text-indent:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">收集个人数据类型:QQ昵称;</span>
                    </p>
                    <p class=MsoNormal style="text-indent:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;white-space:normal;word-break: break-word;">第三方SDK隐私政策:https://wiki.connect.qq.com/qq%e4%ba%92%e8%81%94sdk%e9%9a%90%e7%a7%81%e4%bf%9d%e6%8a%a4%e5%a3%b0%e6%98%8e;</span>
                    </p>
                </li>
                <li>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">SDK名称:com.tencent.mm.opensdk:wechat-sdk-android-without-mta&nbsp;(腾讯微信快速登录SDK);</span>
                    </p>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">使用目的:用于用户在登录时，可以通过微信方式快速登录应用;</span>
                    </p>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">使用方式:微信登录;</span>
                    </p>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">收集个人数据类型:微信昵称;</span>
                    </p>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;white-space:normal; word-break:break-world;">第三方SDK隐私政策:https://www.wechat.com/zh_cn/privacy_policy.html;</span>
                    </p>
                </li>
                <li>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">SDK名称:com.huawei.hms:scan:1.3.0.300&nbsp;(华为扫码SDK); </span>
                    </p>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">com.huawei.agconnect:是com.huawei.hms:scan:1.3.0.300的依赖包。 </span>
                    </p>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">使用目的：用于用户可以通过扫描二维码方式快速添加设备；(com.huawei.hms) </span>
                    </p>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">使用方式：二维码扫描 </span>
                    </p>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">收集个人数据类型:无 </span>
                    </p>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">第三方SDK隐私政策:无 </span>
                    </p>
                </li>
                <li>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">SDK名称:com.flurry.android:analytics:12.13.0@aar&nbsp;(安卓端数据统计分析SDK);</span>
                    </p>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">使用目的：用于分析用户在使用过程中捕捉异常错误日志(com.flurry.android);</span>
                    </p>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">收集个人数据类型：无;</span>
                    </p>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">第三方SDK隐私政策:无;</span>
                    </p>
                </li>
                <li>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">SDK名称:com.google.android:flexbox:2.0.1&nbsp;这是Material&nbsp;Design组件的支持库;</span>
                    </p>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">使用目的：用于应用界面中布局、界面设计使用；(com.google.android)</span>
                    </p>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">收集个人数据类型:无</span>
                    </p>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">第三方SDK隐私政策:无</span>
                    </p>
                </li>
                <li>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">SDK名称:com.google.android.material:material:1.3.0&nbsp;这是Material&nbsp;Desig组件的支持库;</span>
                    </p>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">使用目的:用于应用界面中布局,界面设计使用;(com.google.android)</span>
                    </p>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">收集个人数据类型:无</span>
                    </p>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">第三方SDK隐私政策:无</span></p>
                </li>
                <li>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">SDK名称:com.iflytek(科大讯飞/讯飞SDK);&nbsp;&nbsp;&nbsp;</span>
                    </p>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">使用目的：用于控制家中设备的开、关操作;</span>
                    </p>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">使用方式:用户可以通过语音方式;</span>
                    </p>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">收集个人数据类型:将获取MAC地址,地理位置,IMEI,设备序列号,IMSI,安装列表,SD卡数据,设备ID,ANDROID ID个人信息;</span>
                    </p>
                    <p class=MsoNormal style="margin-left:21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;white-space:normal;word-break:break-word;">第三方SDK隐私政策:https://www.xfyun.cn/doc/asr/voicedictation/online_iat_sdkprivacy.html</span>
                    </p>
                </li>
            </ul>
            <h3><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">1</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">.</span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">3</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        <font face="宋体">这些个人信息可能会被如何使用</font>
                    </span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        
                    </span></b></h3>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">收集个人信息的目的在于向您提供产品和</font>
                    /或服务，并且保证我们遵守适用法律。您特此同意我们可以出于本隐私政策规定的目的处理个人信息，并向我们的关联公司（涉及通信、社交媒体、技术和云业务）和第三方服务供应商（定义如下）披露个人信息。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">我们可能会将个人信息用于下列目的：</font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l3 level1 lfo4;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>1)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">提供、处理、维护、改善、开发我们的商品和</font>/或给您的服务，以及通过设备或全宅卡威提供的服务。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l3 level1 lfo4;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>2)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">与您就您的设备、服务或任何普通查询（例如更新、设备固件</font>/软件升级、客户咨询支持、相关信息、通知）等进行交流。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l3 level1 lfo4;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>3)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">开展市场营销相关活动，例如提供营销和推广相关的资料和更新。获取更多市场营销和推广相关的活动信息，请查看后示的题为</font>&#8220;直接市场推广"的内容。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l3 level1 lfo4;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>4)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">分析和开发与我们产品及服务的使用相关的统计信息，以更好地改进我们的产品和服务。</font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l3 level1 lfo4;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>5)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">储存并维护与您相关的信息，用于我们运营业务或履行法律义务。</font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l3 level1 lfo4;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>6)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">无需与我们的服务器通信，提供本地服务。</font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=22 style="margin-left:42.0000pt;text-indent:-21.0000pt;mso-char-indent-count:0.0000;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l3 level2 lfo4;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>a)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">关于我们如何使用您的信息（其中可能包含个人信息）</font>,下面提供了更多详细信息：
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l3 level1 lfo4;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>7)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">创建您的全宅卡威账号。在通过网页或我们的移动设备创建账号时收集的个人信息用于建立用户的个人账户和资料页。</font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l3 level1 lfo4;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>8)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">
                        提供基于位置的服务。在使用全宅卡威及接入全宅卡威的设备时，我们可能会使用位置信息判断设备时区，以保证在设备中准确显示时间、登录服务地区、获取相关地区天气等信息。以及根据您的选择为您提前开启相应设备等体验。您可以随时进入设备设置或停止使用这一应用程序来关闭这一功能。
                    </font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l3 level1 lfo4;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>9)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">设备接入。设备通过</font>App进行操作，需要验证其设备的真实性，因此我们会需要通过核验出厂
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l3 level1 lfo4;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>10)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">MAC、DID信息来确认设备的合法身份。此外，设备需要连接网络使用的情况下，需要通过APP对设备进行相应的网络设置，此过程需要您录入相应的Wi-Fi名称及密码，该信息仅用于设备配网使用。您填写的Wi-Fi及密码数据，我们会加密存储在设备本地，不会上传服务器，您可以随时进入设备删除该信息。同时，我们需要通过判断IP、网络信号为设备选择最优联网方式，来保障设备的联网稳定性。</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l3 level1 lfo4;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>11)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">设备状态显示。可以允许您远程查看设备状态，以便您时刻查看设备运行情况。</font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l3 level1 lfo4;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>12)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">
                        设置自动化任务。您设备上报的事件信息（信息来自于您添加的设备，具体的可设置信息取决于您对该设备的隐私授权范围，每个添加设备会提供设备提供方的隐私政策相关条款由您选择是否同意）</font>
                    ,可以用于创建属于您个人的自动化任务。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l3 level1 lfo4;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>13)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">提供推送服务。账号和</font>IMEI号码也将用于提供全宅卡威推送服务，为用户发送设备通知。您可以随时通过更改消息设置&#8220;项下的偏好选项来关闭这一功能。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l3 level1 lfo4;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>14)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">验证用户身份。全宅卡威使用</font>ECV值来验证用户身份，确保黑客或未经授权的人员无法登录。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l3 level1 lfo4;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>15)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">收集用户反馈。您选择提供的反馈对帮助全宅卡威改进我们的服务而言极为珍贵。为了跟踪您选择提供的反馈，全宅卡威可能会使用您所提供的个人信息与您联系，并保留记录。</font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l3 level1 lfo4;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>16)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">
                        发送通知。我们可能会不时使用您的个人信息来发送重要通知，例如有关设备异常运行状态的通知、您定制的设备推送通知（具体需要根据您购买添加设备的情况而定）、我们的条款、条件和政策变更。</font>
                </span><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <h3><b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">1.</span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">4</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        <font face="宋体">直接市场推广</font>
                    </span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        
                    </span></b></h3>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">我们可能会使用您的姓名、电话号码、电子邮箱地址、全宅卡威及全宅卡威账号、</font>
                    IMEI号码向您提供全宅卡威公司及其合作伙伴（提供网络、移动应用程序和云产品和服务）的产品和服务相关的推广资料。我们严格遵守您所在地的数据保护法（一些法律可能会要求独立的明确许可）,所以我们只会在征得您事先明确的同意，并且会通过一项清晰的肯定操作，确认我们已征得您的同意或您表示不反对之后，才会使用您的个人信息。如果您不希望再收到某些类别的电子邮件，您可以点击位于页面下方的链接退订邮件。我们不会将您的个人信息传送给我们的商业伙伴用于直接市场推广。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <h3><b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">1.</span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">5</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        <font face="宋体">我们与谁共享您的信息？</font>
                    </span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        
                    </span></b></h3>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">我们不会将任何个人信息出售给第三方。</font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">我们可能会向第三方（定义见下文）披露您的个人信息，以便提供您要求的产品或服务。</font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">
                        我们可能会向本部分下文列出的第三方服务供应商和关联公司作出披露。在本部分所述的各种情况下，您可以放心，全宅卡威仅会根据您的授权共享您的个人信息。您对全宅卡威的授权将包括各类处理您的个人信息的子处理器。您应当了解，在下文描述的任何情况下，当全宅卡威与第三方服务供应商共享您的个人信息时，全宅卡威会通过合同规定第三方的实践和义务，遵守适用的地方数据保护法。全宅卡威会通过合同保证第三方服务供应商遵守您所属司法管辖区中适用于他们的隐私权标准。
                    </font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <h2><b><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:黑体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">2</span></b><b><span style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        <font face="黑体">与我们集团和第三方服务供应商共享</font>
                    </span></b><b><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:黑体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        
                    </span></b></h2>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">
                        为了顺利地从事商业经营，以向您提供产品和服务的全部功能，我们可能会向其他的全宅卡威关联公司（其涉及通讯、社交媒体、技术或云业务）或我们的第三方服务供应商（我们的邮寄商店、送货服务供应商、电信公司、数据中心、数据存储设施、客户服务供应商、广告和推广服务供应商、代表全宅卡威的代理）
                    </font>
                    [关联公司和/或其他第三方](统称为"第三方服务供应商")披露您的个人信息。此类第三方服务供应商可能代表全宅卡威或出于上述的一项或多项目的处理您的个人信息。在我们的设备上使用某些移动应用程序时，我们可能会和第三方共享您的IP地址，以便为您提供您要求的一些服务。如果您不再希望允许我们共享这些信息，请发送电子邮件到wanghongwei@iet.com.cn联系我们。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <h2><b><span style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">3
                        <font face="黑体">与其他人共享信息</font></span></b><b><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:黑体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        
                    </span></b></h2>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">在适用法律要求时，全宅卡威可能会不经过您的进一步同意而披露您的个人信息。</font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <h3><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">3</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">.</span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">1</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        <font face="宋体">不需要同意的信息</font>
                    </span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        
                    </span></b></h3>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l8 level1 lfo5;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>1)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">
                        我们可能以汇总的形式与第三方（例如我们网站上的广告商）共享匿名信息，用于商业目的；我们可能与其共享我们服务的一般使用趋势，例如在购买某些产品或从事某些交易的特定人群中的客户数量。</font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l8 level1 lfo5;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>2)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">
                        为免存疑，全宅卡威可能在地方数据保护法明确允许的情况下和范围内（例如为了遵循传票）不经您的同意而收集、使用或披露您的个人信息，并且当我们可能会出于诚意相信为了保护我们的权利、保护您的安全或他人安全、为调查欺诈行为或对政府要求作出回应而必需披露时，不经您的同意而披露您的信息。
                    </font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <h2><b><span style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        <font face="黑体">二、安全保障</font>
                    </span></b><b><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:黑体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        
                    </span></b></h2>
            <h3 style="margin-left:21.0000pt;text-indent:-21.0000pt;mso-list:l11 level1 lfo6;">
                <span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>1.<span>&nbsp;</span></span></span>
                <b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        <font face="宋体">全宅卡威的安全措施</font>
                    </span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        
                    </span></b>
            </h3>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">我们承诺保证您的个人信息安全。为了防止未经授权的访问、披露或其他类似风险，我们落实了合理的物理、电子和管理措施流程，保护我们从您对【全宅卡威】</font>
                    APP的使用收集的信息。我们将采取所有合理的措施保护您的个人信息。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">例如，当您访问全宅卡威账户时，您可以选择我们的两步验证程序来保证更好的安全性。当您从全宅卡威设备向我们的服务器发送或收取信息时，我们确保使用安全套接层</font>
                    (SSL)和其他算法对其进行加密。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">
                        您的个人信息全都被储存在安全的服务器上，并在受控设施中受到保护。我们依据重要性和敏感性对您的数据进行分类，并且保证您的个人信息具有最高的安全等级。我们保证通过访问这些信息来帮助向您提供产品和服务的员工和第三方服务供应商具有严格的合同保密义务，如果未能履行这些义务，其将会受到纪律处分或被终止合作。同样，我们对以云为基础的数据存储设有专门的访问控制措施。总而言之，我们定期审查信息收集、储存和处理实践，包括物理安全措施，以防止任何未经授权的访问和使用。
                    </font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">我们将采取所有可行的步骤保护您的个人信息。但是，您应当意识到互联网的使用并不总是安全的，因此，我们不能保证在通过互联网双向传输时任何个人信息的安全性或完整性。</font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">我们将对个人数据泄露行为采取相应措施，如将泄露现象通报相关监管机构，或在某些情况下依照使用法律</font>(包括您当地的数据保护法律）向数据主体通报个人数据泄露行为。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <h3 style="margin-left:21.0000pt;text-indent:-21.0000pt;mso-list:l11 level1 lfo6;">
                <span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>2.<span>&nbsp;</span></span></span>
                <b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        <font face="宋体">您能做什么</font>
                    </span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        
                    </span></b>
            </h3>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l4 level1 lfo7;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>1)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">
                        您可以在保护您的个人信息方面发挥自己的作用，不要向任何人透露您的登录密码或帐户信息，除非此人获得您的正式授权。无论您何时作为全宅卡威账号用户登录全宅卡威，尤其是在他人的计算机或公共互联网终端上登录时，在会话结束时您总应注销登出。
                    </font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l4 level1 lfo7;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>2)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">
                        全宅卡威不对因您未能保持个人信息的私密性而导致第三方访问您的个人信息进而造成的安全疏漏承担责任。尽管有上述规定，如果发生互联网其他任何用户未经授权使用您账户的情况或其他任何安全漏洞，您必须立即通知我们。
                    </font>
                </span><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=22 style="margin-left:42.0000pt;text-indent:-21.0000pt;mso-char-indent-count:0.0000;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l4 level2 lfo7;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>a)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">您的协助将有助于我们保护您个人信息的私密性。</font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <h3 style="margin-left:21.0000pt;text-indent:-21.0000pt;mso-list:l11 level1 lfo6;">
                <span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>3.<span>&nbsp;</span></span></span>
                <b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        <font face="宋体">保留政策</font>
                    </span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        
                    </span></b>
            </h3>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">
                        如有必要实现收集信息的目的，或者遵守适用法律要求或允许，我们将一直保留个人信息。如果有合理的理由认为保留个人信息不能实现收集个人信息的目的，我们将不再保留个人信息，或删除个人信息与特定个人相关联的方式。如果是为了公众利益对信息进行进一步处理归档，为了科学或历史研究目的或根据使用法律进行统计的目的，即使进一步处理信息的目的与原始目的不相符，全宅卡威也可以进一步保留数据。
                    </font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <h3 style="margin-left:21.0000pt;text-indent:-21.0000pt;mso-list:l11 level1 lfo6;">
                <span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>4.<span>&nbsp;</span></span></span>
                <b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        <font face="宋体">访问您的设备上的其他功能</font>
                    </span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        
                    </span></b>
            </h3>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">我们的应用程序可能会访问您设备上的某些功能，例如</font>
                    Wi-Fi网络状态。这些信息用于允许这些应用程序在您的设备上运行，并且允许您与其互动。在任何时候，您可以通过在设备水平上关闭应用程序或者通过wanghongwei@iet.com.cn联系我们来撤销许可。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <h3 style="margin-left:21.0000pt;text-indent:-21.0000pt;mso-list:l11 level1 lfo6;">
                <span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>5.<span>&nbsp;</span></span></span>
                <b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        <font face="宋体">您可以注销您的账号信息</font>
                    </span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        
                    </span></b>
            </h3>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">您可以在全宅卡威“我的-设置-账号注销”功能注销您的账号信息。账号注销后，全宅卡威将不会保存您的任何信息。
                    </font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <h3 style="margin-left:21.0000pt;text-indent:-21.0000pt;mso-list:l11 level1 lfo6;">
                <span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>6.<span>&nbsp;</span></span></span>
                <b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        <font face="宋体">控制设置</font>
                    </span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        
                    </span></b>
            </h3>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">全宅卡威承认每个人对隐私权的关注各不相同。因此，我们提供了一些示例，说明全宅卡威提供的各种方式，供您选择，以限制收集、使用、披露或处理您的个人信息，并控制您的隐私权设置：
                    </font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l9 level1 lfo8;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>1)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">打开或者关闭用户体验计划和位置访问功能；</font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l9 level1 lfo8;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>2)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">登入或登出全宅卡威账户：</font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l9 level1 lfo8;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>3)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">如果您之前因为上述目的同意我们使用您的个人信息，您可以随时通过书面或者向</font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l9 level1 lfo8;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>4)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">wanghongwei@iet.com.cn发送邮件的方式联系我们来改变您的决定。</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <h3 style="margin-left:21.0000pt;text-indent:-21.0000pt;mso-list:l11 level1 lfo6;">
                <span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>7.<span>&nbsp;</span></span></span>
                <b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        <font face="宋体">访问、更新、更正、消除或限制处理您的个人信息</font>
                    </span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        
                    </span></b>
            </h3>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l7 level1 lfo9;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>1)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">您有权要求访问和</font>
                    /或更正我们持有的与您有关的任何个人信息。当您更新个人信息时，在我们处理您的请求前，会要求验证您的身份。一旦我们获得充分信息，可处理您的请求以访问或更正您的个人信息时，我们将在适用数据保护法规定的时间内对您的请求做出回应。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l7 level1 lfo9;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>2)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">根据您的要求，我们可以将收集和处理的您的个人数据副本免费提供给您。对于同一信息的任何额外请求，我们可能会根据使用法律根据实际管理支出收取合理的费用。</font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l7 level1 lfo9;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>3)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">如果您希望请求访问我们持有的个人数据或者如果您认为我们持有的关于您的任何信息是不正确或不完整的，请尽快致信或者向下方提供的电子邮箱地址发送电子邮件联系我们。电子邮箱：
                    </font>wanghongwei@iet.com.cn
                </span><span><a href="mailto:2880680361@qq.com。"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                            <font face="微软雅黑">。</font>
                        </span></a></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l7 level1 lfo9;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>4)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">如果您是《通用数据保护条例（</font>
                    GDPR)》管理下的欧盟用户，您有权限制我们处理您的个人信息，我们将考虑该限制要求的合理性。如果GDPR之中有相关规定，我们将只会根据符合GDPR中适用规定的方式处理您的个人信息，并在解除限制的之前通知您。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l7 level1 lfo9;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>5)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">如果您是《通用数据保护条例（</font>
                    GDPR)》管理下的欧盟用户，您有权不受可能会对您产生法律效果、或者对您造成类似的重大影响的、完全基于自动化处理（包括剖析）决定的影响。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l7 level1 lfo9;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>6)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">如果您是《通用数据保护条例（</font>GDPR)》管理下的欧盟用户，您有权以常用的结构化格式接收您的个人信息，并将该信息传输给另一个数据控制器。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l7 level1 lfo9;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>7)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">与您全宅卡威账户中的个人信息相关的更多详细信息，您可以通过您的设备登入账户来访问和更改。</font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <h3 style="margin-left:21.0000pt;text-indent:-21.0000pt;mso-list:l11 level1 lfo6;">
                <span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>8.<span>&nbsp;</span></span></span>
                <b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        <font face="宋体">撤销同意</font>
                    </span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        
                    </span></b>
            </h3>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l6 level1 lfo10;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>1)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">您可以通过向</font>
                    wanghongwei@iet.com.cn发送邮件的方式联系我们提交请求，撤销同意收集、使用和或披露我们掌握或控制的您的个人信息。我们将会在您做出请求后的合理时间内处理您的请求，并且会根据您的请求，在此后不再收集、使用和/或披露您的个人信息。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l6 level1 lfo10;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>2)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">请注意，您撤销同意会导致某些法律后果。根据您撤销同意让我们处理您的个人信息的范围，这可能表示您不能享受全宅卡威的产品和服务。</font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <h3 style="margin-left:21.0000pt;text-indent:-21.0000pt;mso-list:l11 level1 lfo6;">
                <span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>9.<span>&nbsp;</span></span></span>
                <b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        <font face="宋体">将个人信息转移到您所属的司法管辖区之外</font>
                    </span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        
                    </span></b>
            </h3>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">
                        如果我们需要将个人信息转移到您所属的司法管辖区之外，无论是转移给我们的关联公司（其涉及通信、社交媒体、技术和云业务）或第三方服务供应商时，我们将按照适用法律进行。特别是，我们通过落实合适的安全保障措施，保证所有这类转移满足适用的地方数据保护法的要求。您将有权获知全宅卡威为转移您的个人信息而采取的适当保护措施。
                    </font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">
                        全宅卡威是一家总部位于中国的公司。因此，在遵守使用法律的前提下，我们可能会将您的个人数据转移至全宅卡威集团的任何子公司，根据本隐私政策中描述的目的处理信息。我们也可能会将您的个人数据传输至我们的第三方服务提供商，这些服务提供商可能位于欧洲经济区（
                    </font>EEA)区域之外的国家或地区。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">如果全宅卡威与第三方共享源自</font>
                    EEA区域内的个人数据，无论该第三方是否是EEA之外的全宅卡威子公司，我们都会根据欧盟标准合同条款或GDPR中规定的任何其他保障措施来执行。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">
                        全宅卡威可能使用全宅卡威运营和控制的海外设施处理或备份您的个人信息。目前，全宅卡威在中国深圳、北京设有数据中心。这些司法管辖区有数据保护法。您已知悉，不同适用数据保护法律下的风险不尽相同。但是，这并不会改变我们对遵守本隐私政策保护您的个人信息的所有承诺。
                    </font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">当您选择使用本产品和服务中的其他第三方服务时（如</font>
                    Amazon&nbsp;Alexa,&nbsp;Google&nbsp;Assistant语音服务等）,第三方可能会读取您的相关信息，您的相关信息也可能被传输到您所属的司法管辖区之外的第三方服务器，您同意此种情境的转移。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <h2><b><span style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        <font face="黑体">三、其他规定</font>
                    </span></b><b><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:黑体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        
                    </span></b></h2>
            <h3 style="margin-left:21.0000pt;text-indent:-21.0000pt;mso-list:l2 level1 lfo11;">
                <span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>1.<span>&nbsp;</span></span></span>
                <b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        <font face="宋体">未成年人</font>
                    </span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        
                    </span></b>
            </h3>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l5 level1 lfo12;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>1)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">我们认为监督未成年人使用我们的产品和服务是父母的责任。但是，我们的政策不要求获得未成年人的个人信息，或者向这类人群发送任何促销资料。</font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l5 level1 lfo12;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>2)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">
                        全宅卡威不会寻求或试图寻求接收来自未成年人的任何个人信息。如果家长或监护人有理由相信未成年人未经他们事先同意而向全宅卡威提供了个人信息，请联系我们以确保删除此类个人信息，并保证未成年人取消订阅任何适用的全宅卡威服务。
                    </font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
            <h3 style="margin-left:21.0000pt;text-indent:-21.0000pt;mso-list:l2 level1 lfo11;">
                <span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>2.<span>&nbsp;</span></span></span>
                <b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        <font face="宋体">优先顺序</font>
                    </span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        
                    </span></b>
            </h3>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">如果您同意了适用的用户协议，并且此类用户协议和本隐私政策之间存在不一致，将以此类用户协议为准。</font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <h3 style="margin-left:21.0000pt;text-indent:-21.0000pt;mso-list:l2 level1 lfo11;">
                <span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>3.<span>&nbsp;</span></span></span>
                <b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        <font face="宋体">隐私政策的更新</font>
                    </span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        
                    </span></b>
            </h3>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">
                        我们会对隐私政策进行定期审核，为反映我们信息惯例的变更，我们可能会更新本隐私政策。如果我们对本隐私政策进行重大变更，我们将通过（向您账户指定的邮箱地址发送）电子邮件或在【全宅卡威】</font>
                    APP公布或通过移动设备通知您，这样您可以了解我们收集的信息以及我们如何使用这些信息。此类隐私政策变化将从通知或网站规定的生效日期开始适用。我们建议您定期查阅本网页获取我们隐私权实践的最新信息。您继续使用产品和网站、手机和/或其他任何设备上的服务，将被视为接受更新的隐私政策。在我们向您收集更多的个人信息或我们希望因为新的目的使用或披露您的个人信息时，我们会再次征得您的同意。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <h3 style="margin-left:21.0000pt;text-indent:-21.0000pt;mso-list:l2 level1 lfo11;">
                <span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>4.<span>&nbsp;</span></span></span>
                <b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        <font face="宋体">我是否必须同意任何第三方条款与条件？</font>
                    </span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        
                    </span></b>
            </h3>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">
                        我们的隐私政策不适用于第三方提供的产品和服务。全宅卡威产品和服务可能包括第三方的产品和服务，以及第三方网站的链接。当您使用这些产品或服务时，也可能收集您的信息。因此，我们强烈建议您花时间阅读该第三方的隐私政策，就像阅读我们的政策一样。我们不对第三方如何使用他们向您收集的个人信息负责，也不能控制其使用。我们的隐私政策不适用通过我们的服务链接的其他网站。
                    </font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">当您使用全宅卡威平台上这些特定产品的扩展程序服务时，适用第三方条款和隐私政策，您将同意各扩展程序的条款。</font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <h3 style="margin-left:21.0000pt;text-indent:-21.0000pt;mso-list:l2 level1 lfo11;">
                <span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>5.<span>&nbsp;</span></span></span>
                <b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        <font face="宋体">小工具</font>
                    </span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        
                    </span></b>
            </h3>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">全宅卡威包含一些社交媒体功能，包括</font>
                    微信,QQ小组件。这些功能可能会收集您的IP地址和您在全宅卡威中浏览的页面，并可能设置cookie以使相应功能正常运行。小工具或由第三方托管或直接托管在全宅卡威上。您与这些功能的互动均受其提供公司隐私政策的制约。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <h3 style="margin-left:21.0000pt;text-indent:-21.0000pt;mso-list:l2 level1 lfo11;">
                <span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>6.<span>&nbsp;</span></span></span>
                <b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        <font face="宋体">单点登录</font>
                    </span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        
                    </span></b>
            </h3>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">取决于您所属的司法管辖区，您可以使用微信,</font>
                    QQ提供的第三方授权平台提供的登录服务，来登录到全宅卡威。这些服务将验证您的身份，并让您选择是否与我们分享某些个人信息（例如您的姓名和电子邮件地址）,以预先填写我们的注册表。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <h3 style="margin-left:21.0000pt;text-indent:-21.0000pt;mso-list:l2 level1 lfo11;">
                <span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>7.<span>&nbsp;</span></span></span>
                <b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        <font face="宋体">关于我们系统管理您个人信息的方式</font>
                    </span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        
                    </span></b>
            </h3>
            <h3><b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">7.</span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">1</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        <font face="宋体">如果您是《通用数据保护条例（</font>GDPR)<font face="宋体">》管理下的欧盟用户，全宅卡威将提供：</font>
                    </span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        
                    </span></b></h3>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">运用风险管理手段，提供系统的方法来管理与我们的员工、管理流程和信息系统相关的个人数据。根据</font>GDPR,全宅卡威将使用例如后列的方式：
                </span><span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">(1)设立数据保护官（DPO)负责数据保护，DPO的联系方式是wanghongwei@iet.com.cn;(2)建立数据保护影响评估(DPIA)等流程。</span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                  
                </span></p>
            <h2><b><span style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        <font face="黑体">四、联系我们</font>
                    </span></b><b><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:黑体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        
                    </span></b></h2>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">如果您对本隐私政策有任何意见或问题，或者您对全宅卡威收集、使用或披露您的个人信息有任何问题，请通过下列地址联系我们的数据保护官，并提及</font>"隐私政策":
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">北京欣智恒科技股份有限公司</font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">北京市海淀区海淀南路</font>19号时代网络大厦8层&nbsp;&nbsp;
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">电子邮箱：</font>wanghongwei@iet.com.cn
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">感谢您花时间了解我们的隐私政策！</font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <h2><b><span style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Arial;mso-hansi-font-family:Arial;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        <font face="黑体">五、本次更新</font>
                    </span></b><b><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:黑体;mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;mso-font-kerning:1.0000pt;">
                        
                    </span></b></h2>
            <p class=MsoNormal style="text-indent:21.0000pt;mso-char-indent-count:2.0000;line-height:18.0000pt;mso-line-height-rule:exactly;">
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">我们对隐私政策做出了如下几处重要的更新：</font>
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span></p>
            <p class=MsoNormal style="margin-left:21.0000pt;text-indent:-21.0000pt;line-height:18.0000pt;mso-line-height-rule:exactly;mso-list:l12 level1 lfo13;">
                <span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>1)<span>&nbsp;</span></span></span>
                <span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    <font face="微软雅黑">通过遵守</font>GDPR并提供更好的数据隐私保护，我们更新了GDPR下用户权利的相关内容，以及如何处理欧盟用户的个人信息。另外，我们还描述了我们的数据隐私管理方法。
                </span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
                    
                </span>
            </p>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "App",
    components: {},
};
</script>

<style scoped>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    /* margin-top: 60px; */
}

.agr-box {
    padding: 10px;
}

@font-face {
    font-family: "Times New Roman";
}

@font-face {
    font-family: "宋体";
}

@font-face {
    font-family: "Wingdings";
}

@font-face {
    font-family: "DejaVu Sans";
}

@font-face {
    font-family: "Arial";
}

@font-face {
    font-family: "黑体";
}

@font-face {
    font-family: "Calibri";
}

@font-face {
    font-family: "微软雅黑";
}

@list l0:level1 {
    mso-level-number-format: bullet;
    mso-level-suffix: tab;
    mso-level-text: "";
    mso-level-tab-stop: none;
    mso-level-number-position: left;
    margin-left: 21pt;
    text-indent: -21pt;
    font-family: Wingdings;
}

p.MsoNormal {
    mso-style-name: 正文;
    mso-style-parent: "";
    margin: 0pt;
    margin-bottom: 0.0001pt;
    mso-pagination: none;
    text-align: justify;
    text-justify: inter-ideograph;
    font-family: Calibri;
    mso-fareast-font-family: 宋体;
    mso-bidi-font-family: "Times New Roman";
    font-size: 10.5pt;
    mso-font-kerning: 1pt;
}

h2 {
    mso-style-name: "标题 2";
    mso-style-noshow: yes;
    mso-style-next: 正文;
    margin-top: 13pt;
    margin-bottom: 13pt;
    mso-para-margin-top: 0gd;
    mso-para-margin-bottom: 0gd;
    page-break-after: avoid;
    mso-pagination: lines-together;
    text-align: justify;
    text-justify: inter-ideograph;
    mso-outline-level: 2;
    line-height: 172%;
    font-family: Arial;
    mso-fareast-font-family: 黑体;
    mso-bidi-font-family: "Times New Roman";
    font-weight: bold;
    font-size: 16pt;
    mso-font-kerning: 1pt;
}

h3 {
    mso-style-name: "标题 3";
    mso-style-noshow: yes;
    mso-style-next: 正文;
    margin-top: 13pt;
    margin-bottom: 13pt;
    mso-para-margin-top: 0gd;
    mso-para-margin-bottom: 0gd;
    page-break-after: avoid;
    mso-pagination: lines-together;
    text-align: justify;
    text-justify: inter-ideograph;
    mso-outline-level: 3;
    line-height: 172%;
    font-family: Calibri;
    mso-fareast-font-family: 宋体;
    mso-bidi-font-family: "Times New Roman";
    font-weight: bold;
    font-size: 16pt;
    mso-font-kerning: 1pt;
}
/*
span.10 {
    font-family: "Times New Roman";
}

span.15 {
    font-family: "Times New Roman";
    color: rgb(0, 0, 255);
    text-decoration: underline;
    text-underline: single;
}
*/
p.MsoCommentText {
    mso-style-name: 批注文字;
    margin: 0pt;
    margin-bottom: 0.0001pt;
    mso-pagination: none;
    text-align: left;
    font-family: Calibri;
    mso-fareast-font-family: 宋体;
    mso-bidi-font-family: "Times New Roman";
    font-size: 10.5pt;
    mso-font-kerning: 1pt;
}

span.msoIns {
    mso-style-type: export-only;
    mso-style-name: "";
    text-decoration: underline;
    text-underline: single;
    color: blue;
}

span.msoDel {
    mso-style-type: export-only;
    mso-style-name: "";
    text-decoration: line-through;
    color: red;
}

table.MsoNormalTable {
    mso-style-name: 普通表格;
    mso-style-parent: "";
    mso-style-noshow: yes;
    mso-tstyle-rowband-size: 0;
    mso-tstyle-colband-size: 0;
    mso-padding-alt: 0pt 5.4pt 0pt 5.4pt;
    mso-para-margin: 0pt;
    mso-para-margin-bottom: 0.0001pt;
    mso-pagination: widow-orphan;
    font-family: "Times New Roman";
    font-size: 10pt;
    mso-ansi-language: #0400;
    mso-fareast-language: #0400;
    mso-bidi-language: #0400;
}

@page {
    mso-page-border-surround-header: no;
    mso-page-border-surround-footer: no;
}

@page Section0 {
    margin-top: 72pt;
    margin-bottom: 72pt;
    margin-left: 90pt;
    margin-right: 90pt;
    size: 595.3pt 841.9pt;
    layout-grid: 15.6pt;
}

div.Section0 {
    page: Section0;
}
</style>
