<template>
  <div class="log-box">
    <div class="none-box" v-if="isEmpty">
       <img src="img/none.png" class="none-img" >
       <div class="text">暂无数据</div>
    </div>
    <!-- {{token.length}}    -->
    <!-- {{homeId}} -->
    <!-- {{this.homeList}} -->
    <div class="top-bar" v-if="selectHomeInfo.length>0">
      <div class="btn" @click="selectHome">{{selectHomeInfo[0].label}}</div>
      <div class="btn" @click="selectDate">{{date}}</div>
    </div>
  <!-- {{dataLog}} -->
    <div class="content-box" :name="item.id" v-for="(item,index) in dataLog" :key="index">
      <div>
        <div>
          <span class="timeDD">{{getDD(item.createTime)}}</span>
          <span class="timeMM">{{getMM(item.createTime)}}月</span>
          <span class="week">星期{{getdd(item.createTime)}}</span>
        </div>
        <div>
        <span class="yuan"></span>
        <span class="day">{{getHHmm(item.createTime)}}</span><span class="status-box">执行-{{item.customDeviceName}}</span>
        </div>
      </div>
    </div>

    <calendar @change="onChange" class="calendar-box" :show.sync="showCalendar" />
     <VuePicker :data="pickData"
      :showToolbar="true"
      @cancel="cancel"
      @confirm="confirm"
      :visible.sync="pickerVisible"
    />
  </div>
</template>

<script>
import {getHomesList} from '@/api/homesInfo/homes_info'
import {sceneLogList} from "@/api/deviceLog/device_log";
import moment from 'moment'
import 'moment/locale/zh-cn'
import VuePicker from 'vue-pickers'

var tdata = []

export default {
  name: "log",

  data() {
    return {
      isEmpty: false,
      activeNames: ["1"],
      calendarShow: true,
      minDate: new Date(), //设置过期时间为当天
      commandSource: 2,
      commandDirection: 0,
      showCalendar: false,
      date: "日期",
      token: "",
      homeId: "",
      selectHomeInfo: [],
      pickerVisible: false,
      pickData: [
        tdata
      ],
      result: '',
      page: {
        pageSize: 50,
        currentPage: 1,
        total: 0,
      },
      sceneAction: {
      },
      dataLog: []
    };
  },
  props: {
    msg: String,
  },
  components: {
    VuePicker
  },
  created: function() {
    this.date = moment().format('YYYY-MM-DD');
    this.getToken();
    //  this.getHome();
  },
  mounted: function() {},
  methods: {
    getHHmm(createTime) {
      return moment(createTime).format("HH:mm")
    },
    getMM(createTime) {
      return moment(createTime).format("MM")
    },
    getDD(createTime) {
      return moment(createTime).format("DD")
    },
    getYear(createTime) {
      return moment(createTime).year()
    },
    getdd(createTime) {
      return moment(createTime).format("dd")
    },
    getToken() {
      this.$bridge.callHandler('getAccessToken',null,(res)=>{
      //   // alert('获取app响应数据:'+res)
        this.token = "Bearer " + res;
        // this.onLoad(this.page);
        this.$bridge.callHandler('getId',null,(id)=>{
           console.log(" id "+id);
          //  alert(" id "+id);
          this.homeId = id;
      this.getHome();
        })
      })
    },
    //将家庭id传给app
    setHomeId() {
      let data = {
        homeId: this.selectHomeInfo[0].value,
        name: this.selectHomeInfo[0].label,
        masterFlag: this.selectHomeInfo[0].masterFlag
      }
      this.$bridge.callHandler('getHomeId', JSON.stringify(data), () => {
      })
    },
    getHome() {
      getHomesList(this.token).then(res => {
        console.log(" res ", res);
        this.homeList = res.data.data.records;
        if (this.homeList != null) {
          console.log(" this.homeList.length ", this.homeList.length);
          for (let i = 0; i < this.homeList.length; i++) {
            if (this.homeList[i].id == this.homeId) {
              this.selectHomeInfo = [{
                label: this.homeList[i].name,
                value: this.homeList[i].id,
                masterFlag: this.homeList[i].masterFlag
              }]
            }
            tdata.push({
              label: this.homeList[i].name,
              value: this.homeList[i].id,
              masterFlag: this.homeList[i].masterFlag
            })
          }

          this.onLoad(this.page);
        }
      })
    },
    selectDate() {
      this.showCalendar = true;

    },
    onChange(date) {
      this.showCalendar = false;
      console.log(" date " + date);
      // alert(date);
      this.date = date.format('YYYY-MM-DD');
      this.onLoad(this.page);
    },

    selectHome() {
      this.pickerVisible = true
    },
    cancel() {
      this.result = 'click cancel result: null'
      this.onLoad(this.page);
    },
    confirm(res) {
      this.selectHomeInfo = res;
      this.homeId = this.selectHomeInfo[0].value;
      this.onLoad(this.page);
      this.setHomeId();
    },
    onLoad(page) {
      this.dataLog = []
      this.loading = true;
      // this.query.homeId = this.homeId;
      this.isEmpty = true;
      let params = {
        homeId: this.homeId,
        commandDirection: 1,
        commandSource: 2,
        day: this.getDD(this.date),
        month: this.getMM(this.date),
        year: this.getYear(this.date),
        index: page.currentPage,
        pageSize: page.pageSize
      }
      sceneLogList(
          this.token,
          params
      ).then((res) => {
        const data = res.data.data;
        console.log(" data ", data);
        //this.activeNames =  data.records[0].id;
        this.page.total = data.total;
        this.dataLog = data.records;
        if (this.dataLog.length > 0) {
          this.isEmpty = false;
        }
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>

.top-bar{
    position: fixed;
    width: 100%;
    background-color: white;
    z-index: 999;
   /* border-bottom: 1px solid #ebeef5; */
}
.top-bar .btn{
  text-align: center;
  width: 50%;
  float: left;
  height: 40px;
  line-height: 40px;
}

.log-box {
  text-align: left;
}
.log-box .day {
  font-size: 16px;
  color: #333333;
  line-height: 40px;
  margin-left:15px;
}
.log-box .month {
  margin-left: 10px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 19px;
}
.week {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 19px;
  right: 40px;
  position: absolute;
  margin:16px 0 0 0;
  display:inline-block;
}
.log-body {
  margin-left:6px;
  padding:0 0 5px 20px;
  border-left:2px solid #E5E5E5;
}
.status-box {
  float: left;
  display:block;
}
.sub-title{
  display:block;
}
.sub-content{
  display:block;
}
.success {
  color: green;
}
.fail {
  color: #EB1F1F;
}
.status-box {
  float: right;
  margin-right: 5px;
}
.content-box{
  padding:40px 0 0 20px;
}
.timeDD{
    color:#333333;
    font-size:35px;
    display:inline-block;
    margin:0 10px 0 0;
}
.timeMM{
    color:#999999;
    font-size:18px;
    display:inline-block;
}
.yuan{
    display:inline-block;
    width: 10px;
    height: 10px;
    border: 2px solid #6776F5;
    border-radius: 50%;
}
.none-box{
  position: absolute;
  width: calc(100% - 20px); 
  margin-top: 240px;
  z-index: 999;
  text-align: center;
}
.none-img{
 width: 40%;
}
</style>
