<template>
  <div class="log-box">
    <div class="top-bar">
      <div class="btn" @click="selectDate">{{date}}<img src="/img/arrDnR.png"> </div>
    </div>
    <el-collapse accordion v-model="activeNames" class="content-box">
       <el-collapse-item class="log-collapse " :name="index" v-for="(item,index) in logParams" :key="index">
        <template slot="title">
          <span class="day">{{ item.params.day }} </span>
          <span class="month">{{ item.params.month }}月</span>
          <span class="week">{{ weeks[item.params.week] }}</span>
        </template>
        <div class="log-body">
          <div class="log-time" v-for="(itemSub,indexSub) in item.data" :key="indexSub">
            <div>
              <span>{{ getHHSS(itemSub.createTime) }}</span>
              <span v-if=" itemSub.isCustomize && itemSub.attributeCode !== 'color'" class="status-box">{{itemSub.customizeValue}}</span>
              <span v-if="itemSub.attributeCode === 'readStatus'" class="status-box">读取</span>
              <span v-if="itemSub.attributeCode === 'color'" class="status-box colorBox" :style="{backgroundColor:'#'+itemSub.customizeValue}" ></span>
              <span class="status-box">{{ itemSub.stateName }}</span>
            </div>
          </div>
          <div class="downloading" @click.stop.prevent="loadingDeviceLog(index)">
            <div :class="{down:true,downjian:isLoadingList[index] === 0,trun:isLoadingList[index] === 1,nodata:isLoadingList[index]===2}">
              {{ showTipsList[index] }}
            </div>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
     <calendar @change="onChange" class="calendar-box" :show.sync="showCalendar" />
  </div>
</template>

<script>
import {appLogList} from '@/api/deviceLog/device_log'
import moment from 'moment'
import 'moment/locale/zh-cn'

export default {
  name: "log",

  data() {
    return {
      id:'',
      token:"",
      activeNames: ["1"],
      weeks:["星期一","星期二","星期三","星期四","星期五","星期六","星期日"],
      showCalendar: false,
      date: "",
      selectHomeInfo:{},
      result: '',
      isLoadingList: [],
      showTipsList: [],
      page: [{
        pageSize: 50,
        currentPage: 1,
        total: 0,
      },{
        pageSize: 50,
        currentPage: 1,
        total: 0,
      },{
        pageSize: 50,
        currentPage: 1,
        total: 0,
      },{
        pageSize: 50,
        currentPage: 1,
        total: 0,
      }],
      query:{
        deviceId:''
      },
      logParams:[]
    };
  },
  props: {
    msg: String,
  },
  components: {
    
  },
  created: function() {
    this.date = moment().format('YYYY-MM-DD');
    this.getToken();
  },
  mounted: function() {
  },
  methods: {
    getHHSS(createTime){
      return moment(createTime).format("HH:mm:ss")
    },
    selectDate() {
      this.showCalendar = true;
      // this.date = "";
    },
    onChange(date) {
     this.showCalendar = false;
      console.log(" date " + date);
      this.date = date.format('YYYY-MM-DD');
      let date0 = moment(this.date);
      let date1 = moment(this.date).subtract(+1, 'days');
      let date2 = moment(this.date).subtract(+2, 'days');
      let date3 = moment(this.date).subtract(+3, 'days');
      this.isLoadingList = [0,0,0,0];
      this.showTipsList = ['','','',''];
      this.page[0].currentPage = 1;
      this.page[1].currentPage = 1;
      this.page[2].currentPage = 1;
      this.page[3].currentPage = 1;
      this.logParams = [];
      this.logParams.push(
      {
        params:{
          year:date0.year(),
          month:date0.month()+1,
          day:date0.date(),
          week:date0.weekday(),
        },
        data:[]
      },
      {
        params:{
          year:date1.year(),
          month:date1.month()+1,
          day:date1.date(),
          week:date1.weekday(),
        },
        data:[]
      },
      {
        params:{
          year:date2.year(),
          month:date2.month()+1,
          day:date2.date(),
          week:date2.weekday(),
        },
        data:[]
      },
      {
        params:{
          year:date3.year(),
          month:date3.month()+1,
          day:date3.date(),
          week:date3.weekday(),
        },
        data:[]
      });

      this.onLoad(this.page[0],this.logParams[0].params,0);
      this.onLoad(this.page[1],this.logParams[1].params,1);
      this.onLoad(this.page[2],this.logParams[2].params,2);
      this.onLoad(this.page[3],this.logParams[3].params,3);
    },
    getToken(){
      this.$bridge.callHandler('getAccessToken',null,(res)=>{
        // alert('获取app响应数据:'+res)
        this.token = "Bearer " + res;
        this.$bridge.callHandler('getId',null,(id)=>{
          console.log(" id "+id)
          //  alert(" id "+id);
          this.id = id;
          this.onChange(moment());
        })
      })
    },
    selectHome () {
      this.pickerVisible = true
    },
    cancel () {
      this.result = 'click cancel result: null'
    },
    confirm (res) {
      this.selectHomeInfo = res;
      // this.onLoad(this.page,{date:this.date});
    },
    onLoad(page, params = {},index) {
      this.loading = true;
      let devParams = {
        "deviceId":this.id,
        "commandSource":1,
        "commandDirection": 1,
        "index":page.currentPage,
        "pageSize":page.pageSize,
      }
      appLogList(
        this.token,
          Object.assign(params, devParams)
      ).then((res) => {
        this.page[index].currentPage++;
        let lastlistnum = res.data.data.records.length;
        this.logParams[index].data = this.logParams[index].data.concat(res.data.data.records);
        this.loading = false;
        if(lastlistnum > 0){
          this.isLoadingList[index] = 0;
        }else{
          this.isLoadingList[index] = 2;
          this.showTipsList[index] = "无";
        }
      });
    },
    loadingDeviceLog(index){
      if( this.isLoadingList[index] !== 0 ){
        return;
      }
      this.isLoadingList[index] = 1;
      this.onLoad(this.page[index],this.logParams[index].params,index);
    }
  },
};
</script>

<style scoped>

.downloading{
  text-align: center;
  padding: 0;
  width: 100%;
}
.down{
  display: inline-block;
  height: 20px;
  width: 20px;
}
.downjian{
  border-right: 1px solid;
  border-bottom: 1px solid;
  transform: rotate(45deg);
}
.trun {
  animation: turn 1s linear infinite;
  border-radius: 50%;
  border-bottom: 1px solid #4e4141;
  border-top: 1px solid #4e4141;
}
.nodata {
  color: #999999;
}
@keyframes turn {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(90deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(270deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

.top-bar{
    position: fixed;
    width: calc(100% - 20px); ;
    background-color: white;
    z-index: 999;
   /* border-bottom: 1px solid #ebeef5; */
}
.top-bar .btn{
  text-align: center;
  width: 100%;
  float: left;
  height: 40px;
  line-height: 40px;
  font-weight: 400;
  color: #333333; 
}
.top-bar img{
  width: 10px;
  margin-left: 5px;
}
.log-box {
  text-align: left;
}
.log-box .week{
  font-weight: 400;
  color: #999999;
}
.log-box .day {
  /* font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500; */
  font-size: 25px;
  color: #333333;
  line-height: 40px;
}
.log-box .month {
  margin-left: 10px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 19px;
}
.log-box .week {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 19px;
  right: 40px;
  position: absolute;
}
.log-body {
  margin-left: 20px;
}
.log-time {
  border-top: 1px solid #ebeef5;
  height: 40px;
  line-height: 40px;
}
.el-collapse-item__header{
  height: 40px !important;
  line-height: 40px !important;
}
.el-collapse-item__content {
  padding-bottom: 0px !important;
}
.status-box {
  float: right;
  margin-right: 5px;
}
.colorBox {
  width: 10px;
  height: 10px;
  margin-top: 15px;
}
.shangbao {
  color: green;
}
.xiakong {
  color: red;
}
.content-box{
  padding-top: 40px;
}

.none-box{
  position: absolute;
  width: calc(100% - 20px); 
  margin-top: 240px;
  z-index: 999;
  text-align: center;
}
.none-img{
 width: 40%;
}
</style>
