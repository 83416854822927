<template>
  <div class="gateway">
<!--    {{deviceClass.productName}}-->
    <div>
      <img :src="deviceClass.installImgId"/>
    </div>
    <div class="contentClass" v-html="deviceClass.installContent">
    </div>
  </div>
</template>
<script>

import {getDetail} from "@/api/deviceClass/device_class";

export default {
  name: "guide",
  data() {
    return {
      result: '',
      token: "",
      resDeviceType: "",
      deviceClass: {}
    };
  },
  components: {
  },
  created: function() {
    this.getToken();
    // this.onLoad(this.page);
  },
  methods: {
    getToken(){
      this.$bridge.callHandler('getAccessToken',null,(res)=> {
        console.log(" getAccessToken " + res);
        this.token = "Bearer " + res;
        this.$bridge.callHandler('getDeviceType', null, (resDeviceType) => {
          console.log(" resDeviceType (productCode) " + resDeviceType);
          this.resDeviceType = resDeviceType;
          getDetail(this.token, this.resDeviceType).then(res => {
            this.deviceClass = res.data.data;
            console.log(" data ", this.deviceClass);
          })
        })
      })
    },
  },
};
</script>
 

<style>
.gateway {
  padding: 20px;
}
.gateway img {
  margin: 40px 0;
  /* margin-top: 120px; */
width: 136px;
height: 136px;
}

.gateway .title {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 23px;
}
.gateway p {
  padding: 0 40px;
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
}
.contentClass{
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  white-space: pre-line;
  color: #666666;
  line-height:15px;
  text-align: left;
}
</style>
